.modal-with-icon {
  padding: 13px;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 519px;

  .modal-with-icon-close {
    margin-left: auto;
  }

  .modal-with-icon-icon {
    margin-top: 30px;
  }

  .title {
    margin-top: 24px;
    text-align: center;
  }

  .message {
    margin-top: 10px;
    width: 400px;
  }

  .modal-button {
    margin-top: 48px;
  }
}
