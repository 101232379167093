.machine-session-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  background-color: $color-container-secondary;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.35));

  .machine-session-header-button-back {
    margin-right: 24px;
  }

  .machine-session-header-info {
    display: flex;
    flex-direction: column;

    .status-container {
      display: flex;
      align-items: center;
    }
  }

  .machine-session-header-button {
    margin-left: auto;
  }
}
