.modal-confirm {
  padding: 28px;
  z-index: 999;

  &-title {
    margin-bottom: 20px;
  }

  &-text {
    margin-bottom: 41px;
  }

  &-buttons {
    display: flex;
  }

  button:first-child {
    margin-left: auto;
    margin-right: 1rem;
  }
}

.modal-confirm-overlay {
  z-index: 999;
}
