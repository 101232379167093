.screenshots-overlay-modal {
  border-radius: 20px;
  .screenshots-overlay-container {
    display: flex;
    background-color: $color-layer-1;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 8px 8px 20px #000000;

    .screenshots-overlay-left {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 40px 36px;

      .screenshots-overlay-left-title,
      .screenshots-overlay-left-eyebrow {
        margin-left: 24px;
      }

      .screenshots-carousel-timestamp {
        top: 4px;
        left: 4px;
      }

      .default-file-image {
        height: 241px;
      }

      .screenshots-carousel-image-container {
        display: flex;
        align-items: center;
        background-color: $color-layer-2;
        min-width: 890px;
        min-height: 501px;
      }
    }

    .carousel-root {
      position: relative;

      .carousel {
        &.carousel-slider {
          padding: 0px 24px;
          position: relative;
        }

        .slider-wrapper {
          width: 890px;
          height: 506px;
        }

        .thumbs-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          overflow-x: hidden;
          overflow-y: none;
          width: 890px;

          .control-arrow {
            &.control-prev,
            &.control-next {
              width: 32px;
              height: 32px;
              background: $color-layer-3;
              border-radius: 8px;
              opacity: 1;

              &:hover {
                background: rgba(255, 255, 255, 0.4);
                cursor: auto;
              }

              &::before {
                content: none;
              }
            }

            &.control-next {
              right: 10px;
              top: 40px;
              &::after {
                content: "";
                display: block;
                box-sizing: border-box;
                position: absolute;
                width: 10px;
                height: 10px;
                border-bottom: 1px solid;
                border-right: 1px solid;
                transform: rotate(-45deg);
                right: 13px;
                top: 11px;
                color: $color-white;
              }
            }

            &.control-prev {
              top: 40px;
              left: 10px;
              &::after {
                content: "";
                display: block;
                box-sizing: border-box;
                position: absolute;
                width: 10px;
                height: 10px;
                border-top: 1px solid;
                border-left: 1px solid;
                transform: rotate(-45deg);
                left: 13px;
                top: 11px;
                color: $color-white;
              }
            }
          }
        }

        .thumb {
          width: 130px !important;
          height: 73px;
          padding: 0px;
          margin-right: 22px;
          border: 2px solid transparent;
          &.selected {
            border: 2px solid rgba(239, 124, 47, 0.8);
          }

          .default-file-image {
            height: 42px;
          }

          .screenshots-carousel-image-container {
            display: flex;
            align-items: center;
            background-color: $color-layer-2;
            width: 130px;
            min-width: 130px;
            height: 73px;
            min-height: 73px;
          }
        }

        .thumbs-wrapper {
          margin: 24px;
        }

        .thumbs {
          margin: 0;
          padding: 0;
        }
      }

      .screenshots-carousel-button,
      .screenshots-carousel-button {
        width: 48px;
        height: 48px;

        position: absolute;
        z-index: 2;
        top: calc(50% - 15px);
        cursor: pointer;

        &-left {
          left: 0px;
        }
        &-right {
          right: 0px;
        }
      }
    }

    .screenshots-overlay-right {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 372px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: $color-container-secondary;

      .screenshot-name {
        margin-top: 75px;
      }

      .screenshot-tags {
        width: 325px;
        margin-top: 24px;
      }

      .screenshot-description {
        margin-top: 24px;
        flex: 1 1;
      }

      .screenshot-additional-info {
        margin: 24px 0;
        display: flex;
        flex-direction: column;

        &-row {
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .screenshots-overlay-button-row {
      display: flex;
      justify-content: space-between;
      margin: 24px;
    }

    .screenshots-overlay-pull-right {
      align-self: stretch;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      button:first-child {
        margin-right: 1rem;
      }

      .dropdown-actions {
        top: 0px;
        .dropdown-actions-list {
          margin-top: 25px;
          margin-left: -152px;
        }
      }
    }

    .screenshots-overlay-pull-down {
      margin-top: auto;
    }
  }
}
