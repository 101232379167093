.account-page {
  display: flex;
  flex-direction: column;

  &-title {
    margin: 40px 0px 27px 750px;
  }

  &-tabs {
    margin-left: 280px;
  }
}
