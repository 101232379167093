.modal-cleaning-and-measurement {
  padding: 32px 52px;

  &-title {
    margin-bottom: 12px;
  }

  &-description {
    margin-bottom: 32px;
  }

  &-subtitle {
    margin-bottom: 32px;
  }

  &-label {
    margin-bottom: 24px;
  }

  &-buttons {
    margin-top: 32px;
    display: flex;

    &-cancel {
      margin-right: 16px;
      margin-left: auto;
    }
  }

  &-checkbox-container {
    margin-top: 24px;
    height: 200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    &-inner {
      display: flex;
      width: 340px;
      margin-bottom: 24px;
    }
  }
}
