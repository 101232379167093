.live-session-dropdown-actions {
  cursor: pointer;
  position: relative;

  .icon-container {
    padding-top: 5px;
  }

  &-opaque {
    opacity: 0.8;
  }

  &-list {
    background: $color-layer-2;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6), -4px -4px 6px rgba(255, 255, 255, 0.04); //todo - find the right style
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    top: 1rem;
    right: -0.25rem;
    width: 15rem;
    z-index: 2;

    &-option-container {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 1rem;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &-icon {
        margin-right: 1rem;
        position: relative;
      }
    }
  }
}
