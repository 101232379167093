.container-scrollable {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.center-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
