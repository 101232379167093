.card {
  background: $color-layer-1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6), -4px -4px 6px rgba(255, 255, 255, 0.04);
  border-radius: 15px;

  &-hover:hover {
    background: $color-layer-hover;
  }

  &-active {
    background: $color-orange;
  }
}

.card .card-hover:hover {
  background: $color-layer-hover;
}
