.notification-container {
  min-width: 340px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  position: fixed;
  z-index: 999;
  left: 20px;
  bottom: 10px;

  &-message {
    margin-right: 50px;
  }

  &.global {
    position: fixed;
    z-index: 999;
    left: 20vw;
    top: 10px;
    margin-left: auto;
    width: 60vw;

    .notification-container-message {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .notification-container-close-button {
      margin-left: auto;
    }
  }

  &-primary {
    background: $color-orange;
  }

  &-success {
    background: $color-green;
  }

  &-error {
    background: $color-red;
  }

  &-alert {
    background: $color-blue;
  }

  &-neutral {
    background: $color-gray;
  }
}
