.volume-control-container {
  margin-left: 0.8125rem;

  &:hover {
    .volume-control-input {
      display: inline-block;
    }
  }

  .volume-control-icon {
    margin-left: 0.8125rem;
  }

  .volume-control-input {
    margin-left: 0.8125rem;
    width: 80px;
    display: none;
  }
}
