.dropdown-actions {
  cursor: pointer;
  position: relative;

  &-opaque {
    opacity: 0.6;
  }

  &-list {
    background: $color-layer-2;
    box-shadow: 0px 6px 24px rgba(38, 44, 71, 0.16);
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    top: 20px;
    width: 14rem;
    z-index: 2;

    &-option-container {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 1rem;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &-icon {
        margin-right: 1rem;
        position: relative;
      }
    }
  }
}
