.modal-versions {
  padding: 24px 32px;

  &-title {
    margin-bottom: 20px;
  }

  &-table {
    margin-bottom: 20px;
  }

  &-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
