.active-station-select {
  padding: 24px 18px;

  &-title {
    padding-bottom: 12px;
    border-bottom: 1px solid $color-divider;
  }

  &-option {
    margin-top: 12px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    height: 32px;
    background: rgba(143, 146, 161, 0.2);
    opacity: 0.99;
    border-radius: 8px;

    &.selected {
      background: $color-orange;
    }
  }
}
