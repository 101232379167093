@import "../../styles/variables";
@import "../../styles/mixins";

@import "./pages/PrepareSession/PrepareSession";
@import "./pages/Projects/Projects";
@import "./pages/Project/Project";

.fullheight {
  min-height: 100%;
}

.flex {
  display: flex;
  flex-direction: column;
}

.badge {
  display: inline-block;
  padding: 0 5px;
  border-radius: 16px;
  color: #27292d;
  background-color: $color-orange;

  &.large {
    margin-left: 8px;
    font-family: "DIN2014-bold";
    font-size: 16px;
    width: 21px;
    height: 21px;
  }

  &.small {
    font-size: 12px;
  }
}

// #zmmtg-root {
//   display: none;
// }
