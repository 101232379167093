.form-section {
  background: $color-container-primary;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  border-radius: 1.25rem;
  margin: 0 auto;
  padding: 2rem 4rem;

  &-secondary {
    background: $color-container-secondary;
    box-shadow: none;
  }

  &-title {
    margin-bottom: 0.75rem;
  }

  &-subtitle {
    margin-bottom: 2.5rem;
  }

  &-separator {
    margin-top: 2rem;
  }

  .selection-input {
    width: 100%;
  }

  &-lowmargin {
    margin-bottom: 0.25rem;
  }

  &-doublemargin {
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  &-textarea {
    width: 100%;
  }
}

.form-section-noborder {
  padding-left: 0;
  padding-right: 0;
}

.form-section-secondary {
  background: $color-container-secondary;
}
