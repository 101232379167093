@font-face {
  font-family: "DIN2014-demi";
  src: url("./../fonts/DIN2014/DIN2014-Demi.woff2") format("woff2"),
    url("./../fonts/DIN2014/DIN2014-Demi.woff") format("woff"),
    url("./../fonts/DIN2014/DIN2014-Demi.ttf") format("truetype");
}

@font-face {
  font-family: "DIN2014-bold";
  src: url("./../fonts/DIN2014/DIN2014-Bold.woff2") format("woff2"),
    url("./../fonts/DIN2014/DIN2014-Bold.woff") format("woff"),
    url("./../fonts/DIN2014/DIN2014-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DIN2014-light";
  src: url("./../fonts/DIN2014/DIN2014-Light.woff2") format("woff2"),
    url("./../fonts/DIN2014/DIN2014-Light.woff") format("woff"),
    url("./../fonts/DIN2014/DIN2014-Light.ttf") format("truetype");
}

@font-face {
  font-family: "DIN2014";
  src: url("./../fonts/DIN2014/DIN2014-Regular.woff2") format("woff2"),
    url("./../fonts/DIN2014/DIN2014-Regular.woff") format("woff"),
    url("./../fonts/DIN2014/DIN2014-Regular.ttf") format("truetype");
}

input,
textarea {
  font-family: "Din2014";
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
body {
  color: $color-white;
  margin: 0;
  padding: 0;
  font-family: "DIN2014";
  font-weight: normal;
}

body {
  background: $color-container-primary;
  font-family: "DIN2014";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

* {
  box-sizing: border-box;
}

// h1 {
//   font-size: 48px;
//   line-height: 53px;
//   letter-spacing: 0.03em;
// }

// h2 {
//   font-weight: bold;
//   font-size: 36px;
//   line-height: 44px;
//   letter-spacing: 0.01em;
// }

// h3 {
//   font-weight: bold;
//   font-size: 30px;
//   line-height: 36px;
// }

// h4 {
//   font-weight: bold;
//   font-size: 24px;
//   line-height: 28px;
// }

// h5 {
//   font-weight: bold;
//   font-size: 18px;
//   line-height: 22px;
// }

// h6 {
//   font-weight: bold;
//   font-size: 15px;
//   line-height: 18px;
//   letter-spacing: 0.01em;
// }

// .eyebrow {
//   font-family: "DIN2014";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 12px;
//   text-align: center;
//   letter-spacing: 0.05em;
//   text-transform: uppercase;
//   color: $color-gray;
// }

.pointer {
  cursor: pointer;
}

// .bold {
//   font-weight: bold;
// }

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.white {
  color: $color-white;
}

.gray {
  color: $color-gray;
}

.gray-layer-3 {
  color: $color-layer-3;
}

.gray-layer-1 {
  color: $color-layer-1;
}

*[role="button"] {
  outline: none;
  cursor: pointer;
}

.buttonAsLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: $color-off-white;
  text-decoration: none;
  cursor: pointer;
}

.vertical-flex {
  display: flex;
  align-content: center;
  align-items: center;

  .mr2 {
    margin-right: 2rem;
  }

  .mr3 {
    margin-right: 3rem;
  }
}

.height4 {
  height: 4rem;
}

.width100 {
  width: 100%;
}

.width75 {
  width: 75%;
}

.width50 {
  width: 50%;
}

.width25 {
  width: 25%;
}

.flex-end {
  justify-content: flex-end;
}

.mt1-5 {
  margin-top: 1.5rem;
}

.mr05 {
  margin-right: 0.5rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr1-5 {
  margin-right: 1.5rem;
}

.mr2-5 {
  margin-right: 2.5rem;
}

.ml-auto {
  margin-left: auto;
}

.ml05 {
  margin-left: 0.5rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml1-5 {
  margin-left: 1.5rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml2-5 {
  margin-left: 2.5rem;
}

.border-collapse {
  border-collapse: collapse;
}

.bg-gray {
  background: $color-gray;
}

.bg-off-white {
  background: $color-off-white;
}

.bg-orange {
  background: $color-orange;
}

.no-list {
  list-style: none;
  padding-inline-start: 0;
}

.relative {
  position: relative;
}

div:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border: solid 3px transparent;
  background: $color-container-secondary;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 10px 10px $color-layer-3;
  border: solid 3px transparent;
}
