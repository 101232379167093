.camera-streams {
  background: $color-container-primary;

  .camera-streams-progress-bar-container {
    display: flex;
    height: 11px;
    justify-content: space-between;
    align-items: center;
    margin: 0 1.5rem 1.5rem 1.5rem;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 1.5rem 1.5rem 1.5rem;

    &-play {
      margin-left: 0.8125rem;
    }

    .progress-line {
      margin: 0 1rem;
      width: 60%;
    }

    &-time {
      margin-left: 12px;
    }

    svg,
    div {
      cursor: pointer;
    }
  }

  &-bottom-pull-right {
    display: flex;
    margin-left: auto;

    &-button {
      position: relative;
      outline: none;
      align-items: center;
      display: flex;
      margin-left: auto;
      text-transform: uppercase;
    }

    .icon-container {
      margin-right: 1rem;
      position: relative;
      top: 0.05rem;
    }
  }
}
