.projects-sidebar {
  background: $color-container;
  min-height: calc(100vh - 70px);
  margin-left: auto;
  min-height: calc(100vh - 101px);
  min-width: 23.75rem;

  .tabs-container {
    padding-top: 2.5rem;
  }
  .tabs-small {
    .react-tabs__tab {
      width: 50%;
    }
  }
}
