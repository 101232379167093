.machine-sessions {
  background: $color-container-secondary;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 40px;
  min-height: calc(100vh - 313px);

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    max-height: 50px;
    width: 100%;
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-date {
      display: flex;
      flex-direction: column;

      .icon-container {
        margin-top: 50px;
      }

      &-month {
        margin-bottom: 0.25rem;
        margin-top: 0.1rem;
      }
    }
  }
}
