.new-project-card-outer-container {
  align-items: center;
  box-shadow: -4px -4px 12px rgba(255, 255, 255, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.8);
  background: $color-layer-1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 320px;

  &:hover {
    filter: brightness(120%);
    transition: filter 0.1s ease-in-out;
  }

  .new-project-icon {
    filter: drop-shadow(-2px -3px 5px rgba(255, 255, 255, 0.1)) drop-shadow(5px 10px 8px rgba(0, 0, 0, 0.2));
  }

  .new-project-text {
    margin-top: 2.55rem;
  }
}
