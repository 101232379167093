.session-row-in-progress {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 16px;

  .session-row-details-status-column-progress {
    margin-left: 5px;
  }

  &-info-header {
    .session-row-details-info-column {
      font-family: "DIN2014-demi" !important;
    }
  }
}
