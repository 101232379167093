.session-row-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-container-secondary;
  height: 77px;
  width: 100%;
  padding-left: 24px;
  padding-right: 16px;
  border-radius: 15px;

  &-status-container {
    display: flex;

    .status-icon-container {
      margin-right: 23px;
    }
  }
}
