.programs {
  min-width: 344px;
  background: $color-container-secondary;
  padding: 36px 16px;
  min-height: calc(100vh - 313px);

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-list {
    list-style: none;
    padding-inline-start: 0;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-white;
      cursor: pointer;
      height: 54px;
      padding: 18px;
      border-radius: 12px;
      background: $color-layer-2;
      margin-bottom: 8px;

      &-active,
      &:hover {
        background: $color-layer-3;
      }
    }
  }
}
