.session-row-details {
  padding: 19px 24px;
  display: flex;

  &-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    &-with-margin {
      margin-left: 60px;
      min-width: 290px;
      margin-right: 16px;
      width: auto;
      flex: 1;
    }

    &-first-row {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 16px;

      &-material {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 12px;
        margin-right: 53px;
        width: 308px;
        height: 86px;
        background: rgba(143, 146, 161, 0.2);
        border-radius: 8px;

        &-inner {
          display: flex;

          &-text {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
          }
        }
      }

      &-uploads {
        margin-top: 0.5rem;
        display: flex;
        width: 100%;
        flex-direction: column;

        &-container {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        }

        &-snapshots,
        &-measurements {
          display: flex;
          margin-top: 0.5rem;

          &-snap {
            display: inline-flex;
            margin-right: 10px;
            cursor: pointer;
          }
        }

        &-left {
          min-width: 342px;
        }

        &-right {
          display: flex;
          flex-direction: column;

          &-header {
            display: flex;
          }

          &-values {
            margin-top: 4px;
            height: 42px;
            display: flex;
            align-items: center;
          }
        }

        &-file {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          width: 42px;
          height: 42px;
          background: #3a3c3c;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
          border-radius: 4px;
        }
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-column {
      flex-basis: 161px;
      min-width: 161px;
      flex: 1;
    }

    &-top {
      margin-top: 5px;
      padding-bottom: 1rem;
      border-bottom: 1px solid $color-divider;

      &-run {
        display: flex;
        justify-content: space-between;
      }
    }

    &-top {
      height: 50px;
    }

    &-header,
    &-top {
      display: flex;
      justify-content: flex-start;
    }

    &-top {
      padding-right: 24px;
    }

    &-new,
    &-pic {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }

    &-new {
      border: 1px dashed rgba(143, 146, 161, 0.6);
    }

    &-pic {
      background: $color-dark-gray;
      color: $color-gray;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
    }
  }

  &-status-column {
    flex-basis: 218px;
    min-width: 218px;
    flex: 1;
  }
}
