.session-replay-page-container {
  flex: 1;
  display: flex;

  .session-replay-page-left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .session-replay-page-left-side-inner {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      padding-top: 32px;
      min-height: calc(100vh - 270px);

      &.rendering {
        min-height: calc(100vh - 170px);

        .table-container-div-scrollable-content {
          min-height: calc(100vh - 202px);
        }
      }

      .session-replay-page-table {
        display: flex;
        flex-direction: column;
      }

      .table-container-div-scrollable-content {
        overflow: auto;
        height: calc(100vh - 302px);
        width: auto;
        min-width: 892px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        &-notes {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #3f3f3f;
        }
      }
    }
  }

  .session-replay-page-right-side {
    width: 346px;
    padding-top: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .session-replay-page-session-timer-container {
      margin-top: 24px;
    }
  }

  .table-skeleton {
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-rows {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .card-video-uploading {
    padding: 24px;

    &-title {
      margin-bottom: 28px;
    }

    &-inner {
      height: 210px;
      padding: 48px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
