.second-nav-container {
  display: flex;
  background: $color-container-third;
  height: 80px;
  align-items: center;
  padding-left: 164px;
  padding-right: 227px;

  .second-nav-container-session-title {
    margin-left: 24px;
  }

  .push-right {
    margin-left: auto;
  }

  .next-button {
    margin-left: 20px;
  }
}
