.custom-link {
  font-style: normal;
  font-weight: normal;
  font-family: "DIN2014";
  font-size: 14px;
  line-height: 22px;
  color: $color-white;
  text-decoration-line: underline;

  &:hover {
    color: $color-gray;
  }
}
