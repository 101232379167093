.card-close-popup-container {
  background: $color-layer-1;
  padding: 1.75rem;
  position: absolute;
  left: calc(50% - 216px);
  top: 45vh;
  z-index: 9;

  &-title {
    margin-bottom: 1.25rem;
  }

  &-subtitle {
    margin-bottom: 2.6875rem;
  }

  &-buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 1.5rem;
    }
  }
}
