.card-session-timer-outer-container {
  padding: 20px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-session-timer-thumbnail-container {
  display: flex;
  margin-right: 0.8125rem;
}

.card-session-timer-info-container {
  display: flex;
  width: 230px;
  flex-direction: column;
}

.card-session-timer-live-label {
  color: $color-orange;
  margin-right: 10px;
}

.card-session-timer-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-session-timer-name-container {
  margin-bottom: 10px;
}

.card-session-timer-controls-container {
  display: flex;
  width: 230px;
  justify-content: space-between;
  align-items: flex-start;
}

.card-session-timer-column {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card-session-timer-header {
  border-bottom: 1px solid $color-divider;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.card-session-timer-ring {
  border-radius: 50%;
  background: linear-gradient(red, purple, orange);
  -webkit-mask: radial-gradient(transparent 89px, #000 90px);
  mask: radial-gradient(transparent 89px, #000 90px);

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.card-session-timer-ring-container {
  margin: 0 auto;
  width: 200px;
}

.card-session-timer-circle {
  left: 1rem;
}

.circleSvg {
  display: inline-block;
  border-radius: 100%;
  position: relative;
}
