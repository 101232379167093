.modal-session-scheduled {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .title {
    margin-bottom: 2.5rem;
    text-align: center;
    width: 297px;
  }

  .message {
    margin-bottom: 2.25rem;
    margin-top: 1.5rem;
    width: 370px;
  }

  .card-session-scheduled-info-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 230px;
  }
}
