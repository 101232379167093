.account-card {
  position: absolute;
  width: 420px;
  height: 285px;
  right: 32px;
  top: 60px;
  padding: 32px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background-color: $color-layer-2;
  z-index: 10;

  &-top-section {
    display: flex;

    &-initials {
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 44px;
      line-height: 66px;
      width: 96px;
      height: 96px;
      border-radius: 48px;
      color: white;
      background-color: $color-orange;
    }

    &-info {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-name {
        margin-bottom: 12px;
      }
    }
  }

  &-controls-section {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    &-row {
      height: 60px;
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 10px;
      }

      &:first-child {
        border-bottom: 1px solid $color-divider;
      }
    }
  }
}
