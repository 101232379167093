.custom-table {
  display: flex;
  flex-direction: column;
  background: $color-container-secondary;
  padding: 9px 18px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.2), 7px 3px 10px rgba(0, 0, 0, 0.2), 0px -3px 6px rgba(0, 0, 0, 0.3);

  &-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $color-divider;
    padding: 16px;

    &:last-child {
      border-bottom: none;
    }
  }
}
