.project-session-header {
  background: transparent;
  box-shadow: none;
  display: flex;

  &-thumbnail {
    margin-left: 12px;
    margin-right: 3rem;
  }

  &-thumbnail-container {
    &-button {
      display: none;
      position: absolute;
      justify-content: center;
      align-items: center;
      top: 170px;
      left: 72px;
      width: 88px;
      height: 30px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0px 0px 11px 11px;

      .icon-container {
        margin-right: 6px;
      }
    }

    &:hover {
      .project-session-header-thumbnail-container-button {
        display: flex;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: relative;

    &-title {
      align-items: center;
      display: flex;
      margin-bottom: 6px;

      &-icon {
        color: $color-gray;
        margin-left: 1.5rem;
      }
    }

    &-subtitle {
      margin-bottom: 6px;
    }
  }
}
