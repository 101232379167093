.video-quality-control-container {
  display: flex;
  margin-left: auto;

  .dropdown-selection.dropdown-small .dropdown-selection-list {
    bottom: 24px;
    width: auto;
  }

  span {
    &:focus {
      outline: none;
    }
  }
}
