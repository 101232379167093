.machine-session-development {
  padding: 32px 64px;

  .machine-session-development-body {
    margin-top: 10px;
  }

  .machine-session-development-label {
    display: block;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .machine-session-development-input {
    margin: 24px 0px;
  }

  .machine-session-development-divider {
    width: 520px;
    height: 0px;
    margin-top: 40px;
    border-bottom: 1px solid $color-divider;
  }
}
