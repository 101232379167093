.modal-view-material {
  display: flex;
  flex-direction: column;
  padding: 12px;

  &-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 12px;

    &-table {
      width: 100%;
      justify-content: space-between;
      display: flex;
      margin-bottom: 31px;
    }

    &-column {
      flex: 1;
    }

    &-half {
      width: 48%;
    }

    &-flex {
      display: flex;
    }

    &-name,
    &-subtitle {
      margin-bottom: 24px;
    }

    &-text {
      margin-bottom: 16px;
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: 33px;

      &-attachments {
        display: flex;
        align-items: center;

        img.rotated {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      &-actions {
        display: flex;
        margin-left: auto;
        :first-child {
          margin-right: 1rem;
        }
      }
    }
  }

  &-attachments {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 20px 24px;

    &-empty {
      margin-left: 12px;
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 45px;

      .btn {
        margin-left: auto;
      }

      &-input {
        display: none;
      }
    }

    &-close {
      display: flex;
      margin-top: auto;

      &-button {
        margin-left: auto;
        margin-right: 50px;
        margin-bottom: 5px;
      }
    }

    &-row {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;

      &-name {
        margin-left: 4px;
      }

      .dropdown-menu {
        padding: 0;
        margin-left: auto;
        .btn {
          padding-right: 6px;
        }
      }

      .dropdown-menu-list {
        right: -15px;
      }
    }
  }

  &-carousel-image-container {
    display: flex;
    align-items: center;
    background-color: $color-layer-2;
    width: 455px;
    height: 274px;
  }

  .carousel-root {
    position: relative;

    .carousel {
      padding: 0px;
      &.carousel-slider {
        padding: 0px 12px;
        position: relative;
      }

      .slider-wrapper {
        border-radius: 16px;
      }

      .thumbs-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: hidden;
        overflow-y: none;

        .control-arrow {
          &.control-prev,
          &.control-next {
            width: 24px;
            height: 24px;
            background: $color-layer-3;
            border-radius: 4px;
            opacity: 1;

            &:hover {
              background: rgba(255, 255, 255, 0.4);
              cursor: auto;
            }

            &::before {
              content: none;
            }
          }

          &.control-next {
            top: 20px;
            right: 0px;
            &::after {
              content: "";
              display: block;
              box-sizing: border-box;
              position: absolute;
              width: 10px;
              height: 10px;
              border-bottom: 1px solid;
              border-right: 1px solid;
              transform: rotate(-45deg);
              right: 10px;
              top: 7px;
              color: $color-white;
            }
          }

          &.control-prev {
            top: 20px;
            left: 0px;
            &::after {
              content: "";
              display: block;
              box-sizing: border-box;
              position: absolute;
              width: 10px;
              height: 10px;
              border-top: 1px solid;
              border-left: 1px solid;
              transform: rotate(-45deg);
              left: 10px;
              top: 7px;
              color: $color-white;
            }
          }
        }
      }

      .thumb {
        width: 42px !important;
        height: 42px;
        padding: 0px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid transparent;
        &.selected {
          border: 1px solid $color-orange;
        }

        .default-file-image {
          height: 42px;
        }

        .modal-view-material-carousel-image-container {
          display: flex;
          align-items: center;
          background-color: $color-layer-2;
          width: 42px;
          min-width: 42px;
          height: 42px;
          min-height: 42px;
        }
      }

      .thumbs-wrapper {
        margin: 12px;
        margin-top: 10px;
      }

      .thumbs {
        margin: 0;
        padding: 0;
      }
    }

    .modal-view-material-carousel-button,
    .modal-view-material-carousel-button {
      width: 24px;
      height: 24px;
      border-radius: 4px;

      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      cursor: pointer;

      &-left {
        left: 0px;
      }
      &-right {
        right: 0px;
      }
    }
  }
}
