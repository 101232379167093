.card-balances {
  &-account {
    margin-top: auto;
    margin-bottom: 24px;

    .card {
      background: $color-layer-2;
      box-shadow: -3px -5px 4px rgba(140, 140, 140, 0.0500164);
      border-radius: 1.875rem;
      border: 2px solid #1d1d1d;
      padding: 1.3125rem;

      .title {
        color: $color-gray;
      }
    }

    &-btn {
      cursor: pointer;
      padding: 5px 12px;
    }

    &-bars {
      margin-top: 1.25rem;

      &-labels {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.5px;

        &-used {
          padding-right: 4px;
        }
      }
    }
  }
}
