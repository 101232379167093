.add-another-part {
  &#{&} {
    align-items: center;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
}
