.card-add-part-attribute-container {
  background: $color-container-primary;
  border-radius: 1.25rem;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.75rem;

  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;

    &-title {
      margin-left: 1rem;
    }
  }
}
