.part-details {
  width: 670px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 24px;
  border-top: 1px solid $color-divider;

  &-image {
    margin-right: 24px;
  }

  &-info {
    border-bottom: 1px solid $color-divider;
    display: flex;
    width: 100%;

    &-inner {
      padding: 5px 0 10px 0;
      min-height: 96px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-button {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: auto;
      .btn {
        padding: 0;
        margin-right: 10px;
      }
    }
  }

  &-features {
    padding: 16px 0 16px 120px;

    &-feature {
      padding: 16px 0 16px 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .btn {
        padding: 0;
        margin-right: 10px;
      }
    }
    .add-feature-button {
      padding: 4px 16px;
      margin: 16px 0;
    }
  }
}
