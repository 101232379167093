.modal-add-snapshot {
  padding: 24px 32px;

  &-top,
  &-bottom {
    display: flex;
    justify-content: space-between;
  }

  &-top {
    &-timestamp {
      display: inline-flex;
      align-items: center;
    }
  }
}
