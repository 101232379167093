.file-modal {
  border-radius: 20px !important;
  .file-modal-container {
    display: flex;
    background-color: $color-layer-1;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 8px 8px 20px #000000;

    .file-modal-left {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 60px 36px 176px 36px;

      &-top {
        display: flex;
        margin-bottom: 86px;
      }

      .file-modal-left-title {
        margin-left: 24px;
      }

      .files-carousel-timestamp {
        top: 4px;
        left: 4px;
      }

      .default-file-image {
        height: 241px;
      }

      .files-carousel-image-container {
        display: flex;
        align-items: center;
        background-color: $color-layer-2;
        width: 890px;
        height: 501px;
        max-width: 890px;
        max-height: 501px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .carousel-root {
      position: relative;
      width: 935px;
      padding-left: 20px;

      .files-carousel-button {
        width: 48px;
        height: 48px;

        position: absolute;
        z-index: 2;
        top: calc(50% - 15px);
        cursor: pointer;

        &-left {
          left: 0px;
        }
        &-right {
          right: 0px;
        }
      }
    }

    .file-modal-right {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 372px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: $color-container-secondary;

      .file-modal-input {
        width: 325px;
        margin-bottom: 24px;
      }

      .file-description {
        flex: 1 1;
      }

      .file-additional-info {
        margin: 24px 0;
        display: flex;
        flex-direction: column;

        &-row {
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .file-modal-button-row {
      display: flex;
      justify-content: space-between;
      margin: 24px;
    }

    .file-modal-pull-right {
      align-self: stretch;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-left: auto;
      margin-right: 10px;
      button {
        margin-right: 1rem;
      }

      .dropdown-actions {
        top: 0px;
        .dropdown-actions-list {
          margin-top: 25px;
          margin-left: -152px;
        }
      }
    }

    .file-modal-pull-down {
      margin-top: auto;
    }
  }
}
