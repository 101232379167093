.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 12px;

  &.large-margin {
    margin-right: 23px;
  }

  .checkbox {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5), inset -2px -1px 4px rgba(255, 255, 255, 0.2),
      inset 1px 1px 3px rgba(0, 0, 0, 0.5);
    color: black;
    cursor: pointer;
    appearance: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 16px;
    height: 16px;
    border-radius: 4px;

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }

    &:checked {
      background: $color-orange;
      box-shadow: -2px -3px 5px rgba(255, 255, 255, 0.1), 5px 10px 8px rgba(0, 0, 0, 0.2);
      border: none;
    }

    &:disabled {
      //background: $color-container-primary;
      box-shadow: none;
      border: 1px solid rgba(143, 146, 161, 0.8);
      cursor: not-allowed;
    }

    &-error {
      border: 1px solid $color-red;
    }

    + .checkbox-icon {
      color: $color-dark-gray;
      display: none;
      left: 8px;
      font-size: 9px;
      position: absolute;
      top: 1px;
    }

    &:checked + .checkbox-icon {
      display: inline;
    }
  }
}
