.projects-page {
  background: $color-container-primary;

  &-container {
    min-width: 100vw;
    min-height: calc(100vh - 70px);
    display: flex;
    padding-left: 9.375rem;

    &-main {
      padding-top: 2.5rem;
      padding-right: 158px;

      &-projects {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.75rem;

        .card {
          margin-bottom: 60px;
          margin-right: 5rem;
          min-height: 320px;
        }
      }

      &-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-right: 96px;
        min-width: 1136px;
        padding-bottom: 24px;
        border-bottom: 1px solid $color-divider;

        .dropdown-selection {
          .btn,
          .dropdown-selection-list {
            width: 210px;
          }
        }

        &.dropdown-actions {
          filter: brightness(1);
        }
      }
    }
  }
  .projects-skeleton {
    display: flex;

    &-cards {
      margin-bottom: 60px;
      margin-right: 5rem;
      min-height: 320px;
    }
  }
}
