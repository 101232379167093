.card-session {
  display: flex;
  width: auto;
  border-bottom: 1px solid $color-divider;

  .thumbnail-container {
    padding: 24px;
  }

  .info-container {
    padding: 24px 24px 24px 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
