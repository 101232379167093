.modal-upload-file {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3.25rem;

  &-title {
    margin-bottom: 0.75rem;
  }

  &-subtitle {
    margin-bottom: 2rem;
  }

  &-drop {
    align-items: center;
    border: 1.5px dashed $color-gray;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    margin: 0.25rem 0 1.25rem 0;
    width: 100%;
  }

  &-buttons {
    align-self: flex-end;
    display: flex;
    margin-top: 1.5rem;

    button:first-child {
      margin-right: 1rem;
    }
  }

  .custom-input-wrapper,
  .custom-select,
  .custom-textarea-container,
  .tags-container {
    margin-bottom: 24px;
  }
}
