.circleSvg {
  display: inline-block;
  border-radius: 100%;
  position: relative;
}
.circleSvg svg {
  -webkit-filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.1));
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.1));
}
.circleSvgPercentage {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0px auto;
}

.circleSvgPercentageSpan {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.circleSvgProgressCircleBar {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
