.equipment-drawer {
  padding: 0 80px;

  &-fixtures {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &-item {
      align-items: center;
      background: $color-container-secondary;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
      border-radius: 1rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-height: 158px;
      min-width: 158;
      margin-top: 32px;
      margin-right: 45px;
      padding-bottom: 12px;
      width: 158px;

      &-image {
        margin-top: 8px;
        border-radius: 1rem;
        height: 86px;
        width: 142px;
      }

      &-data {
        padding: 0.75rem;
        width: 100%;

        .badge {
          margin-bottom: 3px;
        }

        &-text {
          @include overflow();
          width: 100%;
        }
      }

      &-add {
        margin: auto;
      }
    }
  }

  &-title {
    margin-top: 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-materials {
      margin-top: 80px;
    }
  }
}
