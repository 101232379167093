.action-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 4vw;
  margin-top: 2.75rem;
  padding: 0 1rem;

  &-newsection {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-top: 2rem;

    button.btn {
      color: $color-gray;
    }
  }

  .pointer {
    cursor: pointer;
  }

  &-opacity {
    //opacity: 0.6;
    transition: 0.25s ease-in-out;
  }

  &-rotate-180 {
    transform: rotate(180deg);
  }

  &-head-row {
    align-items: center;
    display: flex;

    .icon-container {
      margin-left: 0.75rem;
      transition: 0.25s ease-in-out;
    }
  }

  &-group {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    &-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.25rem;

      &-col {
        align-items: center;
        display: flex;

        &:first-child {
          padding-left: 1.5rem;
          flex-basis: 40%;
        }

        .dropdown-actions {
          button.btn {
            margin-right: 24px;
            //opacity: 0.6;
            transition: 0.25s ease-in-out;
          }
        }

        &-first {
          cursor: pointer;
          margin-right: 2rem;
        }
      }
    }

    &-row-wrapper {
      box-shadow: 0px 10px 2px -10px $color-divider;
      //border-bottom: 1px solid $color-divider;
      //border-bottom: 1px solid $color-divider;
    }

    &-row-wrapper:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 95%; /* or 100px */
    }

    &-row {
      align-items: center;
      border-radius: 0.5rem;
      display: flex;
      height: 3rem;
      justify-content: flex-end;
      padding: 0 1rem;

      &-border {
        margin: 0 10px;
        border-bottom: 1px solid $color-divider;
      }

      &-first {
        align-items: center;
        display: flex;
        margin-right: auto;
        flex-basis: 90%;
        height: 3rem;
      }

      &-show-on-hover {
        visibility: hidden;
      }

      .action-items-group-row-date-container {
        .action-items-group-row-date {
          min-width: 45px;
          margin-left: auto;
          margin-right: 18px;
          position: relative;

          .react-datepicker-wrapper {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: center;

            .custom-input-wrapper {
              margin-bottom: 0;
            }
          }
        }
      }

      &:hover {
        background: $color-layer-2;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6), -4px -4px 6px rgba(255, 255, 255, 0.04);

        & + .action-items-group-row-border {
          border-bottom: 1px solid transparent;
        }

        .action-items-group-row-show-on-hover {
          visibility: visible;
        }
      }

      .checkbox-container {
        width: 26px;
      }

      &-item {
        padding-left: 14px;
        margin-right: 1.25rem;
        width: 25.3125rem;

        input {
          margin-left: -17px;
        }

        &-placeholder {
          font-size: 14px;
          color: $color-gray;
        }
      }

      .status-selection {
        display: flex;
        justify-content: flex-start;
        flex-basis: 120px;
        margin-right: 40px;
      }
    }
  }

  .custom-input-wrapper {
    margin-bottom: 0;
  }

  .cursor-move {
    cursor: move;
  }
}
