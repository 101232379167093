.statusIcon {
  width: 17px;
  height: 7px;
  border-radius: 4px;

  &-draft {
    background: $color-medium-gray;
  }

  &-notStarted {
    background: $color-white;
  }

  &-inReview {
    background: $color-blue;
  }

  &-inProgress {
    background: $color-orange;
  }

  &-done {
    background: $color-green;
  }
}

.status-icon-container {
  display: flex;
  align-items: center;

  .status-icon-text {
    margin-left: 12px;
    font-size: 15px;
    font-family: "DIN2014-bold";

    &-notStarted {
      color: $color-gray;
    }

    &-inReview {
      color: $color-white;
    }

    &-inProgress {
      color: $color-orange;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    &-Finished {
      color: $color-green;
    }
  }
}
