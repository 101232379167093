.card-todo-outer-container {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.card-todo-thumbnail-container {
  display: flex;
}

.card-todo-info-container {
  display: flex;
  width: 250px;
  flex-direction: column;
}

.card-todo-live-label {
  margin-right: 10px;
}

.card-todo-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-todo-name-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-todo-controls-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  a {
    margin-right: 8px;
  }

  .start-button {
    margin-right: 12px;

    .icon-container {
      margin-right: 4px;
    }
  }

  // .btn.stop-temp-button {
  //   margin-left: auto;
  //   padding: 0px;
  // }
}
