.card-select-files {
  background: $color-container-primary;
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;

  .table-header {
    text-align: left;
  }

  td,
  th {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table-tbody {
    tr td {
      border-top: 1px solid $color-divider;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    tr:last-child td {
      border-bottom: 1px solid $color-divider;
    }

    .remove-column {
      color: $color-off-white;
      padding-right: 2.5rem;
      text-align: right;
    }
  }

  .table-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
