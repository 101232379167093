.outer-container {
  display: block;
  width: 265px;
  height: 279px;
}

.picture-thumbnail {
  display: block;
  position: relative;
  bottom: 279px;
  left: 79px;
  z-index: 99;
}

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 33px;
  padding-bottom: 28px;
}

.position-container {
  padding-top: 14px;
}

.name-container {
  padding-top: 8px;
}

.contact-container {
  padding-top: 2px;
}

.button-container {
  padding-top: 14px;
}
