.fullscreen-video-element {
  color: $color-gray;
  height: 100%;
  width: 100%;

  &-top {
    height: 95%;
    padding-top: 0%;
    padding-bottom: 0%;
  }

  &-bottom {
    height: 5%;
  }
}

.hls-video-bar {
  color: $color-black;
  padding-right: -100px;
}

.fullscreen-live-video-bar {
  color: $color-black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
  background: $color-container-secondary;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);

  &-audio {
    position: fixed;
  }

  &-rewind {
    display: flex;
  }

  &-rewind > * {
    margin: 5px;
  }

  &-progress {
    width: 75%;
    display: flex;
  }

  &-rest {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.hls-container {
  height: 100%;
  width: 100%;
}
