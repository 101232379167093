.container {
  &.container-base {
    background-color: $color-container;
  }

  &.container-primary {
    background-color: $color-container-primary;
  }
  &.container-secondary {
    background-color: $color-container-secondary;
  }
}
