.header-container {
  align-items: center;
  display: flex;
  background: $color-container-primary;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.35));
  height: 70px;
  min-height: 70px;
  padding-left: 24px;
  padding-right: 24px;

  &-fixed {
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  .push-right {
    margin-left: auto;
  }

  .nav-button {
    margin-left: 16px;

    &.with-background {
      border-radius: 16px;
      background: $color-container-secondary;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
}
