.project-timeline {
  //max-height: 75vh;
  overflow: auto;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    &-date {
      align-items: center;
      display: flex;

      .fa-stack {
        height: 2rem;
        width: 2rem;
      }
    }

    &-zoom {
      align-items: center;
      display: flex;

      svg {
        cursor: pointer;
      }

      &-button {
        height: 2rem;
        width: 2rem;
      }

      &-number {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
      }
    }
  }

  &-padding {
    min-width: 10rem;
  }

  &-item {
    background: rgb(48, 48, 48) !important;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 36px !important;
    width: auto !important;

    &-content {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      height: 36px !important;

      &-text {
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-drag {
        margin-left: auto;
        position: relative;
        right: -10px;
        top: 2px;
      }
    }
  }

  .rct-item {
    align-items: center;
    background: $color-container-secondary;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
    border-radius: 0.75rem;
    height: 2.25rem !important;
    color: white;
    display: flex;
  }

  .react-calendar-timeline {
    max-width: 75vw;

    .rct-header-root {
      background: transparent;
      border: 0;
    }

    .rct-sidebar {
      background: $color-container;
      border-right: 1px solid $color-divider;

      .rct-sidebar-row {
        border-bottom: 1px solid $color-divider;
        color: $color-white;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px !important;
        padding: 1.1875rem 2.5rem;
      }
    }

    .rct-vertical-lines .rct-vl {
      border-left: 1px solid $color-divider;
    }

    .rct-dateHeader {
      background-color: transparent;
      border-bottom: 0;
      border-left: 0;
      color: $color-gray;
      cursor: auto;
    }

    .rct-calendar-header {
      border: 0;
      border-bottom: 1px solid $color-divider;
    }

    .rct-hl-even,
    .rct-hl-odd {
      border-bottom: 1px solid $color-divider !important;
    }

    .rct-vertical-lines {
      .rct-vl.rct-vl-first {
        border-left-width: 1px;
      }

      .rct-vl.rct-day-6,
      .rct-vl.rct-day-0 {
        background: none;
      }
    }
  }
}
