.session-row-container {
  &:hover {
    background: $color-layer-hover;
    .session-row {
      .session-row-with-border {
        border-bottom: 1px solid transparent;
      }
    }
    .session-row-expanded {
      border-bottom: 1px solid transparent;
    }
  }

  &:last-child {
    border-bottom: 1px solid transparent;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .session-row-with-border {
      border-bottom: 1px solid transparent !important;
    }
  }

  .session-row {
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .session-row-with-border {
      min-width: 837px;
      flex: 1;
      padding-right: 16px;
      border-bottom: 1px solid $color-divider;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 68px;
    }

    .session-row-arrow {
      outline: none;
      flex-basis: 12px;
      min-width: 12px;
    }

    .dropdown-actions-container {
      margin-left: auto;
      padding-left: 20px;
    }

    .session-row-thumbnail-container {
      padding-left: 10px;
      flex-basis: 50px;
      min-width: 50px;
      height: 24px;
    }

    .session-row-thumbnail-number {
      padding-top: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $color-container-primary;
      color: $color-white;
      position: relative;
      bottom: 10px;
      left: 16px;
      font-size: 12px;
    }

    .session-row-empty-thumbnail {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .session-row-title {
      flex-basis: 169px;
      min-width: 169px;
      width: auto;
    }

    .session-row-value-column {
      flex-basis: 149px;
      min-width: 149px;
      flex: 1;
    }

    .session-row-value-column-small {
      flex-basis: 111px;
      flex: 1;
      min-width: 111px;
    }

    .session-row-context-menu {
      margin-right: 16px;
    }
  }

  .session-row-expanded {
    margin-left: 29px;
    min-width: 827px;
    width: auto;
    border-bottom: 1px solid $color-divider;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 334px;
  }

  .session-row-container-thumbnail-runname {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}
