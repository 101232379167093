.run-plan {
  margin: 0 auto;
  padding: 2rem 5rem;

  &-subtitle {
    margin-top: 0.5rem;
    width: 22rem;
  }

  &-table {
    &-add-button-container {
      margin: 32px 0 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // &.scrollable {
    //   table {
    //     .scrollable-container {
    //       overflow: auto;
    //       //height: 550px;
    //       -ms-overflow-style: none;
    //       scrollbar-width: none;
    //       &::-webkit-scrollbar {
    //         display: none;
    //       }
    //     }
    //   }
    // }

    table {
      border-collapse: collapse;
      td,
      th {
        border-bottom: 1px solid $color-divider;
        padding: 6px 5px;
        text-align: left;
      }

      .dropdown-small {
        .dropdown-selection-list {
          width: 210px;
        }
      }
    }

    .table-header-small {
      min-width: 40px;
      max-width: 40px;
      border-bottom: 1px solid transparent !important;
    }

    .table-header-grayrow {
      min-width: 55px;
      max-width: 55px;
    }

    .table-header {
      min-width: 220px;
      max-width: 220px;
      padding-left: 13px;

      .icon-container {
        padding-top: 3px;
      }
    }

    &-row {
      min-width: 220px;
      max-width: 220px;
      color: $color-white;

      .dropdown-selection :hover {
        .run-plan-table-row-content {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .dropdown-selection,
      .dropdown-selection-disabled {
        position: absolute;
        width: 220px;
        span {
          &:focus {
            outline: none;
          }
        }
      }

      .custom-input-wrapper {
        .custom-input {
          padding: 14px 8px;
        }
      }

      &-run-name {
        display: flex;
        align-items: center;
        margin-right: 8px;
        border-radius: 8px;
        padding: 0.25rem 0.5rem;
        height: 30px;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid $color-gray;
        }
      }

      &-content {
        border-radius: 8px;
        padding: 6px 8px;
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        word-break: break-word;

        .icon-container {
          margin-left: auto;
        }

        &-arrow {
          color: $color-gray;
          margin-right: 0.5rem;
        }
      }

      &-small {
        min-width: 40px;
        min-height: 77px;
        padding-left: 12px !important;
        height: 100% !important;
        border-bottom: 1px solid transparent !important;
      }
    }

    // &-grayrow {
    //   padding-left: 5px !important;
    // }

    &-grayrow,
    &-addrun {
      cursor: pointer;
    }

    &-addrun {
      border-bottom: 1px solid transparent !important;
    }
  }
}
