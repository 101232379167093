.typography {
  color: $color-white;

  &.typography-h1 {
    font-size: 48px;
    font-family: "DIN2014-light", "Oswald", sans-serif;
    line-height: 53px;
    letter-spacing: -0.03em;
  }

  &.typography-h2 {
    font-family: "DIN2014-bold", "Oswald", sans-serif;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.01em;
  }

  &.typography-h3 {
    font-family: "DIN2014-bold", "Oswald", sans-serif;
    font-size: 30px;
    line-height: 36px;
  }

  &.typography-h4 {
    font-family: "DIN2014-bold", "Oswald", sans-serif;
    font-size: 24px;
    line-height: 28px;
  }

  &.typography-h5 {
    font-family: "DIN2014-bold", "Oswald", sans-serif;
    font-size: 18px;
    line-height: 22px;
  }

  &.typography-h6 {
    font-family: "DIN2014-bold", "Oswald", sans-serif;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }

  &.typography-subtitle {
    font-size: 20px;
    line-height: 22px;
  }

  &.typography-display {
    font-size: 16px;
    line-height: 22px;
  }

  &.typography-body {
    font-size: 14px;
    line-height: 22px;
  }

  &.typography-bodySmall {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0;
  }

  &.typography-textLink {
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;

    &:hover {
      color: $color-gray;
    }
  }

  &.typography-label,
  &.typography-eyebrow {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &.typography-tableHeader {
    font-family: "DIN2014";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.03em;
  }

  &.font-weight-normal {
    font-weight: normal;
  }

  &.font-weight-bold {
    font-family: "DIN2014-bold";
  }

  &.font-weight-demi {
    font-family: "DIN2014-demi";
  }

  &.color-initial {
    color: initial;
  }

  &.color-inherit {
    color: inherit;
  }

  &.color-primary {
    color: $color-white;
  }

  &.color-secondary {
    color: $color-gray;
  }

  &.color-off-white {
    color: $color-off-white;
  }

  &.color-orange {
    color: $color-orange;
  }

  &.color-blue {
    color: $color-blue;
  }

  &.color-error {
    color: $color-red;
  }

  &.color-link {
    color: $color-blue;
  }

  &.color-black {
    color: black;
  }
}
