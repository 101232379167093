.modal-image-upload {
  padding: 32px;

  &-title {
    margin-bottom: 8px;
  }

  &-image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
      margin-bottom: 24px;
    }
  }

  &-file-uploader {
    width: 100%;
  }

  &-image,
  &-file-cropper {
    margin-bottom: 24px;
  }

  &-image {
    width: 100%;
  }

  &-buttons {
    display: flex;

    &-right {
      display: flex;
      margin-left: auto;

      .btn:first-child {
        margin-right: 24px;
      }
    }
  }
}
