.account-balance-tab {
  width: 650px;
  background: #303030;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 32px 75px;
  display: flex;
  flex-direction: column;

  &-subtitle,
  .custom-input-wrapper,
  .custom-select {
    margin-bottom: 24px;
  }

  &-message {
    margin-bottom: 24px;
  }
}
