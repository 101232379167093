.modal-add-material {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  &-title {
    margin-bottom: 0.75rem;
  }

  &-subtitle {
    margin-bottom: 2rem;
  }

  &-row {
    display: flex;
    justify-content: space-between;
  }

  &-image-row {
    height: 48px;
    display: flex;
    padding: 6px;
    margin-bottom: 4px;
    border-radius: 4px;
    align-items: center;
    background-color: $color-container-secondary;

    .image-container {
      margin-right: 11px;
    }

    .btn {
      margin-left: auto;
      color: $color-gray;
    }
  }

  &-drop {
    align-items: center;
    border: 1.5px dashed $color-gray;
    border-radius: 0.75rem;
    display: flex;
    height: 145px;
    justify-content: center;
    margin: 0.25rem 0 1.25rem 0;
    width: 100%;
  }

  &-buttons {
    width: 100%;
    display: flex;

    button:first-child {
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  .custom-input-wrapper,
  .custom-textarea,
  .custom-select {
    margin-bottom: 24px;
  }
}
