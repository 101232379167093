.card-bookmark {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1.5rem;

  &-column {
    flex: 1;
    padding-left: 1.5rem;

    h5 {
      margin-bottom: 0.25rem;
    }
  }

  &-active {
    background: $color-layer-3;
  }
}
