.custom-input-wrapper.search-bar-input-container {
  .search-bar-input {
    padding-left: 3.5em;
    color: $color-gray;

    &:focus {
      color: $color-white;
    }

    &:focus + .icon-container .input-icon-right {
      color: $color-white;
    }
  }

  .input-icon-right {
    right: 14.8em;
    color: $color-gray;
  }
}
