.file-uploader {
  margin-bottom: 24px;
  &-dropzone {
    align-items: center;
    border: 1.5px dashed $color-gray;
    border-radius: 1px;
    display: flex;
    height: 145px;
    justify-content: center;
    margin: 0.25rem 0 1.25rem 0;
    width: 100%;
  }

  .default-file-image-container {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
    border-radius: 4px;
    background-color: $color-container-primary;
    .default-file-image {
      width: 32px;
      height: 32px;
      svg {
        color: $color-gray;
      }
    }
  }
}
