.next-machine-session {
  align-items: center;
  border: 2px solid $color-layer-3;
  border-radius: 1rem;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  padding: 24px;

  &-title {
    width: 6.625rem;
  }

  &-date {
    display: flex;

    &-days {
      color: #8e8f8f;
      display: flex;

      &-number {
        margin-right: 0.5rem;
      }
    }

    &-rest {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 2.15rem;

      &-number {
        margin-right: 0.5rem;
      }
    }
  }
}
