/* Colors */
$color-black: #000000;
$color-blue: #179dfa;
$color-dark-gray: #27292d;
$color-gray: #8f92a1;
$color-green: #27ae60;
$color-off-white: #d9d9d9;
$color-orange: #ef7c2f;
$color-red: #f0142f;
$color-white: #ffffff;
$color-medium-gray: #4c4c4c;

/* Gradients */
$color-gradient-orange-source: #ff3a39;
$color-gradient-orange-target: #ef7c2e;

$color-gradient-blue-source: #179dfa;
$color-gradient-blue-target: #36d1dc;

/* Containers */
$color-container: #050505;
$color-container-primary: #1f1f1f;
$color-container-secondary: #303030;
$color-container-third: #2f2f30;

/* Card Layers */
$color-layer-1: #1e1f20;
$color-layer-2: #2b2c2e;
$color-layer-3: #414242;
$color-layer-light: #373a3d;
$color-layer-hover: rgba(255, 255, 255, 0.1);
$box-shadow-floating: 18px 0px 22px rgba(0, 0, 0, 0.35);

/* Dividers */
$color-divider: #3f3f3f;
