.tabs-container {
  .react-tabs__tab-list {
    border-bottom: 1px solid $color-divider;
    list-style: none;
    margin-bottom: 1rem;
    padding-block-start: 0;
    padding-inline-start: 0;
    margin-bottom: 0;
  }

  .react-tabs__tab {
    color: $color-gray;
    cursor: pointer;
    display: inline-block;
    font-size: 1.125rem;
    padding: 0.75rem 2rem 0.75rem 0;
    margin-right: 1.5rem;

    &.react-tabs__tab--selected {
      border-bottom: 0.25rem solid $color-orange;
      color: $color-white;
      font-weight: bold;
    }
  }
}

.tabs-container-noextraborder {
  .react-tabs__tab {
    margin-right: 1.5rem;
    padding-right: 0rem;
  }
}

.tabs-small {
  .react-tabs__tab {
    font-size: 14px;
    line-height: 22px;
    margin-right: 0;
    padding: 0;
    text-align: center;
    //width: 50%;

    &--selected {
      font-weight: normal !important;
    }

    .tab-name {
      display: block;
      padding-bottom: 0.75rem;
    }
  }

  .tab-content-container {
    color: $color-gray;
    text-align: left;
  }
}

.tabs-vertical {
  .react-tabs {
    display: flex;
    flex-direction: row;
  }
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
  }

  .react-tabs__tab {
    width: 255px;
    height: 40px;
    border-radius: 4px;
    text-align: left;
    padding: 9px 16px;
    &.react-tabs__tab--selected {
      border-bottom: 0;
      background-color: $color-container-secondary;
    }
  }

  .tab-content-container {
    color: $color-gray;
    text-align: left;
  }
}

.tabs-sticky {
  .react-tabs__tab-list {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: $color-container-primary;
    z-index: 4;
  }
}
