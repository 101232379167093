.experiment-variables-container {
  background-color: $color-container-primary;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 32px 33px;
  width: 229px;
  height: 646px;

  h5 {
    font-weight: normal;
  }

  .experiment-variables-header {
    margin-bottom: 70px;
  }

  .experiment-variables-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 25px;
  }
}
