.materials-choice {
  &-card {
    background: $color-container-secondary;
    box-shadow: none;
    padding: 0.75rem 1.5625rem;

    &-table {
      border-collapse: collapse;
      width: 100%;

      tr {
        border-bottom: 1px solid $color-divider;
      }

      &-header {
        height: 2.75rem;
        text-align: left;

        &:first-child {
          width: 86px;
        }
      }

      &-row {
        height: 3.5rem;
        cursor: pointer;

        td:first-child {
          padding-left: 24px;
        }

        &.checked {
          background: rgba(143, 146, 161, 0.2);
        }
      }
    }
  }

  &-text {
    padding: 0 1rem 0.5rem 1rem;
  }
}
