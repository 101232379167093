.progress-line {
  background: linear-gradient(180deg, #060606 0%, #1e2222 100%);
  border: 1px solid rgba(143, 146, 161, 0.4);
  box-shadow: 0px 1px 0px #2d3030, 0px 8px 8px #181b1c;
  border-radius: 6.51534px;
  width: 100%;

  &-softshadow {
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.2), 7px 3px 10px rgba(0, 0, 0, 0.2), 0px -3px 6px rgba(0, 0, 0, 0.3);
  }

  .progress-line-filler {
    margin-left: 1px;
    margin-top: auto;
    margin-bottom: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &-orange {
      background: linear-gradient(7.88deg, $color-gradient-orange-source 16.67%, $color-gradient-orange-target 100%);
    }

    &-blue {
      background: linear-gradient(7.88deg, $color-gradient-blue-source 16.67%, $color-gradient-blue-target 100%);
    }
  }

  .progress-line-filler-full {
    margin-right: 1px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
