.tooltip-container-projects {
  background: $color-layer-1;
  box-shadow: 0px 15px 36px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  margin-left: 4px;
  color: $color-white;
}

.project-page {
  background: $color-container-primary;
  display: flex;
  height: calc(100vh - 70px);
  min-height: calc(100vh - 70px);

  &-favbar {
    margin-top: 170px;
    min-width: 60px;
    width: 60px;
    position: relative;

    &-fav {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-bottom: 1.5rem;
      position: relative;

      &-circle {
        &#{&} {
          position: absolute;
          right: 1.75rem;
        }
      }

      &-image:hover {
        filter: drop-shadow(-2px -3px 6px rgba(255, 255, 255, 0.25)) drop-shadow(5px 5px 12px #1a1c1f);
      }
    }

    .container-scrollable {
      padding: 20px;
    }
  }

  &-container {
    width: calc(100vw - 60px);
    max-width: calc(100vw - 60px) !important;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    &-project-settings {
      position: relative;
      height: 0px;
      top: 30px;
      left: calc(100vw - 570px);
      z-index: 5;
    }

    &-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &-tabs {
        flex-grow: 1;

        .react-tabs {
          width: 100%;

          .react-tabs__tab-list {
            display: flex;

            .react-tabs__tab--selected {
              font-weight: normal;
            }

            .react-tabs__tab:last-child {
              margin-left: auto;
            }
          }

          .react-tabs__tab-panel--selected {
            height: 80%;
          }
        }
      }

      &-top {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 2rem;
        padding-bottom: 26px;
        padding-top: 40px;
        width: 100%;
      }
    }
  }

  &-row {
    display: flex;
    //height: 78vh;
  }
}

.tour-buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  button:last-child {
    margin-left: 1rem;
  }
}

.program-details-empty-state {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    height: 140px;
    text-align: center;
  }
}
