.modal {
  z-index: 10;
  background: $color-layer-1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6), -4px -4px 6px rgba(255, 255, 255, 0.04);
  border-radius: 15px;
  border: 2px solid $color-container-secondary;

  &-active {
    background: $color-orange;
  }

  .with-overflow {
    max-height: 100vh;
    overflow: auto;
  }
}

.modal-attachment-container {
  display: flex;
}

.modal-attachment {
  position: relative;
  margin: 18px 0;
  overflow: hidden;
  background: #303030;
  box-shadow: 8px 8px 20px #000000;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 9;
  transition: left 0.3s ease;
  transition: width 0.3s ease;
}

.modal-attachment-hidden {
  left: 0px !important;
  transition: left 0.3s ease;
  transition: width 0.3s ease;
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  &-modal-title {
    margin-bottom: 3rem;
    text-align: center;
  }

  &-dark {
    background-color: $color-container;
  }

  &-new-project {
    padding-top: 40vh;
  }
}
