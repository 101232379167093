.modal-new-project {
  background: transparent;
  border: 0;
  box-shadow: none;
  height: 80vh;
  margin-bottom: 2rem;
  position: relative;

  &-container {
    margin-top: 20vh;
  }

  .custom-input-wrapper,
  .custom-textarea,
  .custom-select {
    margin-bottom: 24px;
  }

  &-cycle-label {
    display: block;
    margin-top: 24px !important;
  }

  &-section,
  &-name {
    background: $color-container-primary;
    border: 2px solid #303030;
    border-radius: 15px;
    box-shadow: none;
  }

  &-name {
    min-height: 436px;
  }

  &-close {
    color: white;
    position: absolute;
    right: 2.875rem;
    top: 2.2rem;
    z-index: 9999;

    button.buttonAsLink {
      color: white;
      font-size: 24px;

      &:focus {
        outline: none;
      }
    }
  }

  &-title {
    margin: 2rem 0 0.75rem 0;
    padding-bottom: 3rem;
    text-align: center;
  }

  &-subtitle {
    margin-bottom: 0.5rem;
  }

  &-name {
    padding: 1.5rem 10rem;

    &-button {
      margin-top: 2.625rem;
      width: 100%;
    }
  }

  &-form {
    width: 650px;
    padding: 1.5rem 3.75rem;

    &-mt {
      margin-top: 2rem;
    }

    &-title {
      margin-bottom: 1.625rem;

      &-row {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .icon-container {
          margin-bottom: 1.625rem;
        }
      }
    }

    &-input {
      //margin-bottom: 1.75rem;

      &-separator {
        border-bottom: 1px solid $color-divider;
        padding-bottom: 12px;
      }

      &-cycle {
        align-items: center;
        display: flex;
      }
    }

    &-date {
      .react-datepicker-wrapper {
        display: flex;
      }

      .custom-input-wrapper {
        margin-bottom: 0;
        .input-icon-right {
          bottom: 12.5px;
        }
      }

      .react-datepicker-popper {
        margin-top: 0;
      }
    }

    &-row {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 2rem 0 10vh 0;

      button:last-child {
        margin-left: 1rem;
        width: 325px;
      }
    }

    &-title-2,
    &-title-3 {
      margin-top: 2rem;
    }

    &-title-2 {
      margin-bottom: 1.625rem;
    }

    &-title-3 {
      margin-bottom: 0.5rem;
    }
  }

  &-navigation {
    margin-bottom: 1rem;
  }

  &-drop {
    align-items: center;
    border: 1.5px dashed $color-gray;
    border-radius: 0.75rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 240px;
    justify-content: center;
    margin: 2.5rem 0 1.25rem 0;
    padding-bottom: 1rem;
    width: 100%;
  }

  &-button {
    box-shadow: none;
  }

  &-attribute {
    position: absolute;
    right: -36vw;
    top: -2.5vh;

    @media only screen and (min-width: 1100px) {
      right: -33vw;
    }

    @media only screen and (min-width: 1400px) {
      right: -29vw;
    }

    @media only screen and (min-width: 1700px) {
      right: -25vw;
    }

    &-later {
      position: absolute;
      right: -26rem;
    }

    &-advanced {
      position: absolute;
      right: -23vw;
      top: -25vh;
    }

    &-bottom {
      position: absolute;
      right: -23rem;
      top: 0.5rem;
    }

    &-features {
      display: none;
      position: fixed;
      left: -7rem;
      top: 22rem;

      @media only screen and (min-width: 1040px) {
        display: block;
      }

      @media only screen and (min-width: 1200px) {
        left: -3rem;
      }

      @media only screen and (min-width: 1400px) {
        left: 2rem;
      }

      @media only screen and (min-width: 1700px) {
        left: 12rem;
      }

      &-title {
        margin-bottom: 1rem;
      }

      &-steps {
        position: relative;

        &-indicator {
          left: 10px;
          position: relative;

          &-bottom {
            top: 8px;
          }

          &-top {
            bottom: 9px;
          }
        }

        .card-add-part-attribute-container-header {
          margin-bottom: 0.5rem;
          margin-top: 1.5rem;
          position: relative;

          &:first-child {
            margin-top: 0;
          }

          .icon-container {
            position: relative;
            top: 2px;
          }
        }

        &-list {
          align-items: center;
          display: flex;
          margin-left: 2rem;

          .icon-container {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  &-features {
    &-line {
      position: absolute;
      width: 1px;
      left: 8px;
      top: -37px;
    }

    &-title-select {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.625rem;

      &-row {
        .custom-select {
          margin-bottom: 0;
        }
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-width: 200px;

        &-icon {
          cursor: pointer;
          margin-left: 1rem;
        }
      }
    }

    &-subtitle {
      margin-bottom: 2rem;
    }

    &-selection {
      margin-bottom: 1.5rem;

      &:first-child {
        margin-top: 2rem;
      }
    }

    &-holes {
      border-top: 1px solid $color-divider;
      margin-top: 3rem;
      padding-top: 3rem;
    }

    &-advanced {
      border-bottom: 1px solid $color-divider;
      border-top: 1px solid $color-divider;
      margin: 2rem 0;
      padding: 2rem 0;

      &-header {
        align-items: center;
        display: flex;
        position: relative;

        .toggle {
          margin-right: 1rem;
        }
      }

      &-cells {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;

        &-cell {
          width: 45%;

          &:nth-child(even) {
            margin-left: 2.5%;
          }

          &:nth-child(odd) {
            margin-right: 2.5%;
          }
        }
      }
    }

    &-add {
      align-items: center;
      display: flex;
      position: relative;

      &-done {
        flex-grow: 1;
        margin-left: 1rem;
      }
    }

    &-collapsed {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .icon-container {
        margin-left: auto;
      }

      &-completed {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-header {
          margin-bottom: 0.75rem;
        }

        &-header,
        &-list {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        &-header-name {
          align-items: center;
          display: flex;
          position: relative;
          right: 1rem;

          .icon-container {
            margin-left: inherit;
            position: relative;
            right: 1rem;
            top: 0.1rem;
          }
        }

        &-list {
          justify-content: flex-start;
          margin-top: 0.25rem;

          &:first-child {
            margin-top: 1rem;
          }

          .icon-container {
            margin-left: inherit;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  &-template {
    display: flex;
    height: 751px;

    &-templates {
      background: $color-container-primary;
      border-bottom-left-radius: 1rem;
      border-top-left-radius: 1rem;
      margin-top: 2.5px;
      height: calc(100% - 2.5px);
      padding: 2rem 2.5rem 2rem 4rem;
      width: 460px;

      &-subtitle {
        margin-bottom: 2.625rem;
        margin-top: 1rem;
      }

      &-session {
        align-items: center;
        display: flex;

        &-icon {
          margin-left: 0.5rem;
        }
      }

      &-input {
        &-plural,
        &-singular {
          .custom-input-wrapper {
            position: relative;

            &:after {
              color: $color-white;
              position: absolute;
              top: 13px;
              right: 115px;
            }
          }
        }

        &-plural .custom-input-wrapper::after {
          content: " Sessions";
        }

        &-singular .custom-input-wrapper::after {
          content: " Session";
        }

        .custom-input.custom-input-number-with-buttons {
          -moz-appearance: textfield;
          padding: 14px 8.5rem 14px 5rem !important;
          text-align: center;
        }

        &-button {
          cursor: pointer;
          font-size: 1rem;
          position: absolute;
          z-index: 2;

          &:first-child {
            left: 1rem;
          }

          &:last-child {
            right: 1rem;
          }

          &:focus {
            outline: none;
          }
        }

        &-below {
          align-items: center;
          border-bottom: 1px solid $color-divider;
          color: $color-off-white;
          display: flex;
          justify-content: space-between;
          margin-top: 0.5rem;
          padding-bottom: 2.5rem;

          &-badge {
            background: #575757;
            border-radius: 0.3rem;
            padding: 2px 4px;
          }
        }
      }

      &-list {
        &-title {
          margin-bottom: 2rem;
          margin-top: 2.5rem;
        }

        &-card {
          margin-top: 1rem;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    &-example {
      background: $color-container-secondary;
      border-bottom-right-radius: 1rem;
      border-top-right-radius: 1rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 2.4rem 2.2rem;
      width: 880px;

      &-title {
        margin-bottom: 0.4rem;
      }

      &-subtitle {
        margin-bottom: 4.8125rem;
        width: 450px;
      }

      &-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &-empty {
        margin-top: 159px;
      }
    }

    &-image {
      height: 550px;
      min-height: 530px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;

      &-big {
        position: relative;
        top: -0.75rem;
      }

      svg {
        width: 100%;
      }
    }
  }
  .react-datepicker__input-container {
    .custom-input-wrapper {
      .icon-container {
        margin-top: 26px;
      }
    }
  }
}
