.status-selection {
  position: relative;

  &-data {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;

    &-icon {
      margin-right: 0.5rem;
    }
  }

  &-dropdown {
    background: $color-layer-2;
    box-shadow: 0px 6px 24px rgba(38, 44, 71, 0.16);
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    width: 12rem;
    z-index: 2;

    &-option div {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 1rem;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      .oval {
        margin-right: 1rem;
        position: relative;
      }
    }
  }
}
