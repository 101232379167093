.dropdown-selection {
  cursor: pointer;
  position: relative;

  &-disabled {
    cursor: auto;

    .planning-session-table-row-content {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &.options-disabled {
    .dropdown-selection-list {
      .dropdown-selection-list-option-container {
        cursor: auto;
        color: $color-gray;

        &:hover {
          background: $color-layer-2;
        }
      }
    }
  }

  &-list {
    background: $color-layer-2;
    box-shadow: 0px 6px 24px rgba(38, 44, 71, 0.16);
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    width: 12rem;
    z-index: 2;

    &-option-container {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 1rem;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &-icon {
        margin-right: 1rem;
        position: relative;
      }
    }
  }

  &.dropdown-small {
    font-size: 12px;
    .dropdown-selection-list {
      width: 10rem;
      &-option-container {
        padding: 0.75rem;
      }
    }
  }

  &.dropdown-medium {
    .dropdown-selection-list {
      margin: 0;
    }
  }

  &.dropdown-large {
    .dropdown-selection-list {
      &-option-container {
        padding: 1.25rem;
        margin: 0;
      }
    }
  }

  &-list-option-last {
    border-top: 1px solid $color-divider;
    color: $color-gradient-blue-source;

    .dropdown-selection-list-option-container {
      justify-content: center;
    }
  }
}
