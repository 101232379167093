.live-session-page-container {
  flex: 1;
  display: flex;

  .live-session-page-left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .live-session-page-left-side-inner {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      padding-top: 32px;
      min-height: calc(100vh - 170px);

      &.skeleton-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .skeleton-container-image {
          margin-bottom: 24px;
        }

        .skeleton-container-title {
          margin-bottom: 16px;
        }

        .skeleton-container-subtitle {
          margin-bottom: 40px;
        }
      }

      .live-session-page-table {
        display: flex;
        flex-direction: column;
      }

      .table-container-div-scrollable-content {
        overflow: auto;
        height: calc(100vh - 202px);
        width: auto;
        min-width: 892px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .live-session-page-right-side {
    width: 346px;
    padding-top: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .live-session-page-session-timer-container {
      margin-top: 24px;
    }
  }

  .table-skeleton {
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-rows {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
