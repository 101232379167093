.card-machine {
  padding: 0.5rem 1.1215rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .name {
    align-self: baseline;
    margin-top: 0.8rem;
  }
}
