.modal-edit-run-plan {
  padding: 40px 60px;

  &-header {
    display: flex;
    justify-content: space-between;

    &-buttons {
      display: flex;
      align-items: center;
      margin-left: auto;

      &-cancel {
        margin-right: 25px;
      }
    }
  }

  .form-section {
    padding: 16px 24px;
  }

  .scrollable-container {
    height: 550px;
  }
}
