.second-nav-bottom-container {
  display: flex;
  justify-content: center;
  background: $color-container;
  height: 100px;
  align-items: center;
}

.second-nav-bottom-nav-step {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.second-nav-bottom-nav-step.active .second-nav-bottom-step-number {
  border: 2px solid $color-orange;
}

.second-nav-bottom-step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-container-secondary;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.second-nav-bottom-step-title {
  margin-left: 14px;
}
