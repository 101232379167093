.App-fixed-header .prepare-session-main {
  padding-top: 70px;
  z-index: 2;

  .second-nav-container {
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  .second-nav-bottom-container {
    margin-top: 80px;
  }
}

.prepare-session-main {
  background: $color-container;
  min-height: 100vh;
  padding-bottom: 9.375rem;

  .custom-input-wrapper {
    margin-bottom: 1.5rem;
  }

  .divider {
    border-color: $color-divider;
    margin-bottom: 1.5rem;
  }

  .react-datepicker-wrapper {
    height: 40px;
    width: 100%;
  }

  .react-datepicker.custom-date-picker .react-datepicker__month-container {
    box-shadow: none;
  }

  .react-datepicker__tab-loop {
    position: relative;

    .react-datepicker-popper {
      transform: none !important;
      position: relative !important;
      top: 1.25rem !important;
    }
  }

  .section-available-machines {
    background: $color-layer-2;
    padding: 1rem 1.75rem;
    position: relative;
    top: -7px;
    transition: display 1s ease;

    &-text {
      margin-bottom: 3rem;
    }
  }

  .section-available-workholdings {
    &-row {
      align-items: center;
      border-bottom: 1px solid $color-divider;
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      padding-bottom: 1.5rem;
    }

    &-no-border {
      padding: 0 12px 12px 12px;
      border: 1px solid transparent;
      margin-top: 0;
      transition: margin-top 1s ease;
    }

    &-border {
      border: 1px solid rgba(143, 146, 161, 0.8);
      box-sizing: border-box;
      border-radius: 1.25rem;
      margin-top: 11px;
      padding: 0 12px 12px 12px;
      transition: margin-top 1s ease;
    }
  }

  .planning-session {
    margin: 0 auto;
    padding: 2rem 5rem;

    &-subtitle {
      margin-top: 0.5rem;
      width: 22rem;
    }

    .empty-state-run-plan {
      margin-top: 50px;
    }

    &-table {
      table {
        border-collapse: collapse;
        width: 100%;

        tr {
          border-bottom: 1px solid $color-divider;
        }

        td,
        th {
          padding: 1rem 0;
          text-align: left;
        }
      }

      &-row {
        color: $color-white;

        &-content {
          align-items: center;
          cursor: pointer;
          display: flex;
          justify-content: space-between;

          &-arrow {
            color: $color-gray;
            margin-right: 1rem;
          }
        }
      }

      &-grayrow,
      &-addrun {
        cursor: pointer;
      }

      &-title {
        margin-bottom: 1.5rem;
        margin-top: 2rem;
      }
    }
  }

  .session-machine-not-selected {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .confirm-session-checkbox-container {
    width: 305px;
    height: auto;
    border-radius: 20px;
    position: absolute;
    left: calc(50vw + 375px);
    display: flex;
    align-items: flex-start;
    border: 1px solid $color-divider;
    padding: 24px;
  }

  .confirm-session-manager-information {
    width: 305px;
    height: auto;
    border-radius: 20px;
    position: absolute;
    left: calc(100px);
    align-items: flex-start;
    border: 1px solid $color-divider;
    padding: 24px;
  }

  .confirm-session {
    margin: 0 auto;
    padding: 2rem 3.75rem;

    &-date {
      align-items: center;
      border-bottom: 1px solid $color-divider;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      padding-bottom: 1.5rem;

      &-col {
        display: flex;
        flex-direction: column;
      }
    }

    &-machine {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;

      &-col {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        max-width: 330px;
      }

      &-minirow {
        border-bottom: 1px solid $color-divider;
        display: flex;
        flex-grow: 1;
        height: 96px;
        justify-content: space-between;
        margin-left: 2rem;

        &-edit {
          align-self: self-start;
        }
      }

      &-runs {
        display: flex;
        justify-content: space-between;
        margin-left: 60px;
        margin-top: 2rem;

        &-col {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-left: 2rem;

          &-title {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    &-goals {
      border-top: 1px solid $color-divider;
      margin-top: 2rem;
      padding-top: 2rem;
      width: 100%;

      &-row {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &-listing {
        padding-bottom: 1rem;
        padding-inline-start: 1rem;

        &-element {
          color: $color-off-white;
        }

        &:last-child {
          border-bottom: 1px solid $color-divider;
          padding-bottom: 2rem;
        }
      }
    }

    &-checkbox {
      align-items: flex-start;
      display: flex;
      margin-top: 1rem;

      label {
        font-size: 12px;
        line-height: 22px;
        text-transform: none !important;
      }
    }

    &-divider {
      margin-top: 1.5rem;
    }

    &-notes {
      margin-top: 1.5rem;
    }
  }

  .cancel-session {
    margin-top: 24px;
    .btn-simple {
      padding-left: 6px;
      font-size: 14px;
      color: $color-gray;
      .left-icon {
        padding-right: 6px;
      }
    }
  }

  .schedule-datepicker-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .schedule-session-checkbox {
    background: $color-layer-2;
    padding: 1rem 2rem;
    padding-bottom: 0;
    position: relative;

    &-inner {
      align-items: center;
      border-top: 1px solid $color-divider;
      display: flex;
      padding: 1.5rem 0;
    }

    &-inner-expanded {
      border-bottom: 1px solid $color-divider;
    }

    &-label {
      text-transform: none !important;
    }
  }

  .checkbox-container .checkbox + .checkbox-icon {
    left: 8px;
  }
}
