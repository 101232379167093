.difuminated-list {
  display: flex;
  flex-direction: column;
  height: 613px;
  overflow: auto;
  width: 305px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(10%, black),
    color-stop(70%, black),
    color-stop(90%, transparent)
  );
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 70%, transparent 90%);
  mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(10%, black),
    color-stop(70%, black),
    color-stop(90%, transparent)
  );
  mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 70%, transparent 90%);

  div {
    margin-bottom: 1rem;
  }

  div:last-child {
    margin-bottom: 0;
  }
}
