.modal-edit-zoom-info {
  padding: 24px 52px;

  &-title {
    margin-bottom: 32px;
  }

  .custom-input-wrapper,
  .custom-select {
    margin-bottom: 24px;
  }

  &-buttons {
    display: flex;

    &-cancel {
      margin-left: auto;
      margin-right: 24px;
    }
  }
}
