.oval {
  position: absolute;
}

.oval-bottom-right {
  bottom: 0;
  right: 0;
}

.oval-bottom-left {
  bottom: 0;
  left: 0;
}

.oval-top-right {
  top: 0;
  right: 0;
}

.oval-top-left {
  top: 0;
  left: 0;
}
