.toggle {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    display: none;
  }

  label {
    background: $color-gray;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5), inset -2px -1px 4px rgba(255, 255, 255, 0.2),
      inset 1px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 0.75rem;
    cursor: pointer;
    display: block;
    height: 20px;
    position: relative;
    text-indent: -9999px;
    width: 34px;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 5px;
    width: 14px;
    height: 14px;
    background: $color-white;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + label {
    background: $color-orange;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}
