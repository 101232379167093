.playback-container {
  align-items: center;
  background: $color-container-secondary;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100px;
  width: 100%;
  padding-left: 85px;
  padding-right: 60px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);

  .progress-bar-container {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      input[type="range"] {
        width: 100%;
      }

      input[type="range"]::-webkit-slider-thumb {
        box-shadow: -3000px 0 0 3000px $color-gradient-orange-source;
      }
    }
  }

  .playback-line {
    .playback-line-progress-container {
      .playback-line-bookmarks-container {
        position: relative;
        top: -28px;
        width: 100%;
      }
      .bookmark-container {
        position: absolute;
        display: inline-block;
        margin-left: -6px;

        .card.card-bookmark {
          z-index: 3;
          position: absolute;
          bottom: 22px;
          right: -150px;
          display: none;
        }

        &:hover {
          .card-bookmark {
            display: flex;
          }
        }
      }
    }
  }
}

.playback-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 10%;
}

.playback-line {
  width: 75%;
  display: flex;
  justify-content: center;
}

.playback-line-progress-container {
  position: relative;
  top: 2px;
  width: 95%;
}

.playback-line-timers {
  position: relative;
}

.playback-add-bookmark {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1rem;
}

.bookmark-group {
  display: inline-block;
  width: 18px;
  height: 18px;
  padding-left: 6px;
  border-radius: 9px;

  background: rgba(239, 124, 47, 0.6);
  font-size: 12px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.bookmarkIcon {
  font-size: 14px;
  margin: 0 1px;
  position: relative;
  opacity: 0.6;
}

.bookmarkOuter {
  .fa-stack-2x {
    width: 1em;
    height: 1em;
  }
}

.bookmarkPlus {
  position: absolute;
  top: 0.75rem;
  left: 0.6rem;
}
