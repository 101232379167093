.custom-input-wrapper {
  .custom-input {
    @include input-arcadia(0, 0);

    &.custom-input-left-icon {
      padding-left: 2.5em;
    }
    &.custom-input-right-icon {
      padding-right: 2.5em;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &-number-with-buttons {
      text-align: center;
    }
  }

  .input-icon-right {
    position: relative;
    right: 1.5em;
    color: $color-white;
  }

  .input-text-right {
    color: $color-white;
    position: relative;
    right: 2.25em;
    top: 1em;
  }

  .input-icon-left {
    position: relative;
    left: 1.5em;
    color: $color-white;
  }

  .icon-container {
    width: 0;
  }
}
