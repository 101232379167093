.tags-container {
  .react-tags {
    position: relative;
    padding: 5px 13px;
    border: 1px solid $color-gray;
    border-radius: 8px;
    cursor: text;
  }

  .react-tags__selected {
    display: inline;
  }

  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 4px 8px 4px 0;
    padding: 0 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: $color-white;
    background: $color-gray;
    font-size: inherit;
    line-height: inherit;

    &:active {
      outline: none;
    }
  }

  .react-tags__selected-tag:after {
    content: "\2715";
    font-weight: bolder;
    font-size: 12px;
    color: $color-white;
    margin-left: 8px;
  }

  .react-tags__search {
    display: inline-block;
    margin: 4px 8px 4px 0;
    padding: 1px 2px;
    max-width: 100%;
    background-color: transparent;

    &-input {
      font-family: "DIN2014";
      color: $color-gray;
      background: transparent;
    }
  }

  .react-tags__search-input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__search-input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  .react-tags__suggestions ul {
    width: 100%;
    margin-top: 2px;
    background: $color-layer-2;
    box-shadow: 0px 6px 24px rgba(38, 44, 71, 0.16);
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    z-index: 2;
  }

  .react-tags__suggestions li {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 1rem;
  }

  .react-tags__suggestions li mark {
    text-decoration: underline;
    color: $color-white;
    background: none;
    font-weight: 600;
  }

  .react-tags__suggestions li:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
