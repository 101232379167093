.program-info-card {
  width: 445px;
  height: 312px;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid $color-gray;
  background: $color-container-primary;

  &-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &:first-child {
      height: 60px;
    }

    &-pull-right {
      height: 60px;
      margin-left: auto;
    }
  }
}
