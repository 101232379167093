.radio-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 12px;

  &.large-margin {
    margin-right: 23px;
  }

  .radio {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5), inset -2px -1px 4px rgba(255, 255, 255, 0.2),
      inset 1px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    opacity: 0;
    visibility: hidden;

    & + .radio-icon {
      cursor: pointer;
      content: "";
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5), inset -2px -1px 4px rgba(255, 255, 255, 0.2),
        inset 1px 1px 3px rgba(0, 0, 0, 0.5);

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.4);
      }
    }

    &:checked + .radio-icon {
      background: transparent;
      border: 0.3rem solid $color-orange;
      box-shadow: none;
    }

    &:disabled {
      & + .radio-icon {
        box-shadow: none;
        cursor: not-allowed;
        background: rgba(143, 146, 161, 0.6);
        border: 1px solid rgba(143, 146, 161, 0.8);
      }
    }

    &-error {
      & + .radio-icon {
        border: 1px solid $color-red;
      }
    }
  }
}
