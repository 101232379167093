.progress-bar-container {
  width: 100%;
  /*Chrome*/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
      overflow: hidden;
      //width: 387px;
      -webkit-appearance: none;
      background: linear-gradient(180deg, #060606 0%, #1e2222 100%);
      border: 1px solid rgba(143, 146, 161, 0.4);
      box-shadow: 0px 1px 0px #2d3030, 0px 8px 8px #181b1c;
      border-radius: 6.51534px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: red;
      margin-top: -1px;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 0px;
      height: 0px;
      background: #434343;
      box-shadow: -387px 0 0 387px $color-gradient-orange-source;
    }
  }
  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: $color-gradient-orange-source;
  }
  input[type="range"]::-moz-range-track {
    background: linear-gradient(180deg, #060606 0%, #1e2222 100%);
    border: 1px solid rgba(143, 146, 161, 0.4);
    box-shadow: 0px 1px 0px #2d3030, 0px 8px 8px #181b1c;
    border-radius: 6.51534px;
  }
  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: $color-gradient-orange-source;
  }
  input[type="range"]::-ms-fill-upper {
    background: linear-gradient(180deg, #060606 0%, #1e2222 100%);
    border: 1px solid rgba(143, 146, 161, 0.4);
    box-shadow: 0px 1px 0px #2d3030, 0px 8px 8px #181b1c;
    border-radius: 6.51534px;
  }

  input[type="range"]:focus {
    outline: none;
  }

  .progress-bar-slider {
    width: 100%;
    margin: 0;
  }

  .progress-bar-tooltip {
    display: none;

    &-value {
      z-index: 999;
      position: relative;
      bottom: 3px;
      padding: 0 5px;
      box-shadow: none;
    }
  }

  &:hover {
    .progress-bar-tooltip {
      display: block;
      position: fixed;
    }
  }
}
