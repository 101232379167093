.program-details {
  width: 100%;
  min-width: 1136px;
  padding: 24px 40px;

  &-header {
    margin-bottom: 24px;
    width: 100%;
    display: flex;

    &-buttons {
      display: flex;
      margin-left: auto;

      button:first-child {
        margin-right: 16px;
      }
    }
  }

  &-row-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-row {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: center;

    .program-details-card:first-child {
      margin-right: 24px;
    }
  }
}
