.card-template {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1.5rem;

  &-thumbnail {
    margin-right: 1.5rem;
  }

  &-column h6 {
    margin-bottom: 0.25rem;
  }

  &-active {
    background: $color-layer-3;
  }
}
