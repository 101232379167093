.file-uploads {
  width: 100%;

  &-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;

    &-title {
      flex: 1;
    }

    &-controls {
      display: flex;
      margin-left: auto;
    }
  }

  &-subtitle {
    border-bottom: 2px solid $color-divider;
    padding-bottom: 0.5rem;
    margin: 0 2.5rem;
  }

  &-starred {
    display: flex;
    flex-wrap: wrap;
    max-width: 1050px;
    margin: 1rem 2.5rem 0 2.5rem;

    &-file {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      margin-bottom: 1rem;

      &-name {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 113px;
        overflow: hidden;

        &-icon {
          padding: 0 5px;
        }
      }

      .card {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 0.6rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-recent {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;

    &-file {
      border-bottom: 2px solid $color-divider;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &-container {
        align-items: center !important;
        display: flex !important;
        height: 4.6875rem !important;
        padding-left: 2.2rem !important;

        &-data {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 2.95rem;

          &-row {
            align-items: center;
            display: flex;
          }
        }

        // &-icon {
        //   font-size: 1.5625rem;
        //   margin-right: 1.25rem;
        // }

        &-card {
          background-color: $color-container-secondary;
          border-radius: 8px;
          margin-right: 20px;
          padding: 11px;
          font-size: 20px;
        }
      }
    }

    &-empty-state {
      margin-left: 42px;
    }
  }

  &-recent-subtitle {
    border-bottom: 2px solid $color-divider;
    margin-top: 1rem;
    padding: 0 2.5rem 0.5rem 2.5rem;
  }
}
