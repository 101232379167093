.live-session-table {
  width: 50vw;
  min-width: 886px;
  .accordion {
    margin-top: 26px;
    border: none;

    .accordion__item {
      margin-bottom: 50px;
    }

    .accordion__heading {
      width: calc(50vw - 180px);
      min-width: 720px;
      display: inline-block;
    }

    .accordion__panel {
      background-color: $color-container-secondary;
      border-radius: 15px;
      padding-left: 0px;
    }

    .accordion__button {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      text-align: left;
      border: none;
      margin: 15px 24px;

      h5 {
        margin-left: 28px;
      }

      &:focus,
      &:active {
        outline: none;
      }
    }

    .accordion-header-add-button-container {
      height: 52px;
      padding-bottom: 20px;
      display: inline-flex;
    }

    .accordion-header-expand-button-container {
      width: 22px;
    }

    .accordion__button[aria-expanded="false"],
    .accordion__button[aria-selected="false"] {
      .accordion-button-open {
        display: none;
      }
    }

    .accordion__button[aria-expanded="true"],
    .accordion__button[aria-selected="true"] {
      .accordion-button-close {
        display: none;
      }
    }

    [hidden] {
      display: none;
      height: 0px;
    }

    .summary {
      width: 100%;
      height: 77px;
      background-color: $color-container-secondary;
      border-radius: 15px;
    }

    .accordion__panel[aria-hidden="false"] + div {
      display: none;
    }
  }
}
