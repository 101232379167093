@mixin input-arcadia($minWidth, $minHeight) {
  @include input-common-styles($minWidth, $minHeight);

  &:focus {
    background: linear-gradient(180deg, #060606 -36.46%, #292929 26.04%);
    border: 1px solid rgba(143, 146, 161, 0.2);
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2), 7px 3px 10px rgba(0, 0, 0, 0.2), 0px -3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  &:disabled {
    background: rgba(143, 146, 161, 0.2);
    border: 1px solid transparent;
  }

  &::placeholder {
    color: $color-gray;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
    background: $color-container-secondary;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    box-shadow: inset 0 0 10px 10px $color-layer-3;
    border: solid 3px transparent;
  }
}

@mixin overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin input-common-styles($minWidth, $minHeight) {
  /* remove default styling */
  font-family: "DIN2014";
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;

  padding: 14px 16px;
  background-color: transparent;
  border: 1px solid rgba(143, 146, 161, 0.8);
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
  color: $color-white;
  min-width: $minWidth;
  min-height: $minHeight;

  &:hover {
    border: 1px solid $color-gray;
  }
}
