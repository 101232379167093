.custom-tooltip {
  padding: 5px 0px 0px 5px;
  border-radius: 4px;

  &.custom-tooltip-dark {
    background: $color-layer-1;
    box-shadow: 0px 15px 36px rgba(0, 0, 0, 0.7);
  }

  &.custom-tooltip-light {
    background: $color-gray;
  }
}
