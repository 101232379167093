.video-element {
  width: 435px;
  height: 308px;
  color: $color-gray;
  padding: 1.3125rem 1.5rem;

  &-top {
    margin-bottom: 1.5rem;

    &-icon {
      margin-right: 1.5rem;
    }

    &-buttons {
      display: flex;
    }
  }

  .video-loading {
    color: white;
    position: absolute;
    left: 20;
    z-index: 10;
  }

  &-camera {
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-arrow {
      cursor: pointer;
      position: absolute;
      z-index: 2;
    }

    &-arrow-ellipse {
      opacity: 0.25;
      transition: 0.5s ease-in-out;
    }

    &-hidden {
      display: none;
    }

    &:hover &-arrow-ellipse {
      background: $color-gray;
      opacity: 1;
    }

    &:hover &-zoom {
      opacity: 1;
    }

    &:hover &-dropdown {
      opacity: 1;
    }

    &-arrow-left {
      left: -0.9rem;
    }

    &-arrow-right {
      transform: rotate(180deg);
      right: -0.9rem;
    }

    &-arrow-top {
      transform: rotate(90deg);
      top: -0.9rem;
    }

    &-arrow-bottom {
      transform: rotate(270deg);
      bottom: -0.9rem;
    }

    &-dropdown,
    &-zoom {
      background: rgba(143, 146, 161, 0.6);
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      z-index: 10;

      position: absolute;
      opacity: 0;
      transition: 0.5s ease-in-out;
      top: 0.25rem;
    }

    &-dropdown {
      right: 0.25rem;
      font-size: 12px;
      line-height: 20px;
    }

    &-zoom {
      left: 0.25rem;
      padding: 0.5rem 0.25rem;

      .icon-container {
        color: $color-white;
        cursor: pointer;

        &:first-child {
          margin-bottom: 0.25rem;
          margin-top: 0.25rem;
        }
      }
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.5rem;

    div {
      cursor: pointer;
    }

    &-icon {
      color: $color-gray;
      padding: 0;
      margin-right: 8px;
    }
  }
}
