.selection-item {
  padding-top: 24px;
  align-items: flex-start;
  border-bottom: 1px solid $color-divider;
  cursor: pointer;
  display: flex;
  height: 144px;
  justify-content: flex-start;

  &-data {
    margin: 0 1rem;
  }

  &-image {
    display: flex;

    .checkbox-container {
      height: 16px;
      margin: auto 12px auto 0;

      .checkbox + .checkbox-icon {
        top: -2px;
      }
    }
  }
}

.selection-item-top {
  position: relative;
}

.selection-item-noborder {
  border-bottom: 0;
}

.selection-item-checked {
  background: rgba(143, 146, 161, 0.2);
}
