.avatar-outer-container {
  display: block;
  width: 56px;
  height: 56px;
  color: $color-white;
}

.avatar-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25px;
  height: 25px;
  bottom: 61px;
  left: 35px;
  border-radius: 50%;
  background: $color-orange;
  border: 5px solid $color-container;
  color: #f3f3f3;
  font-size: 11px;
}

.avatar-notifications.hidden {
  visibility: hidden;
}

.avatar-status {
  position: relative;
  width: 16px;
  height: 16px;
  bottom: 40px;
  left: 42px;
  border-radius: 50%;
  z-index: 99;

  &-online {
    background: $color-green;
    border: 3px solid $color-layer-3;
  }

  &-away {
    background: $color-orange;
    border: 3px solid $color-container;
  }

  &-offline {
    background: $color-gray;
    border: 3px solid $color-container;
  }
}
