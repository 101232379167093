.btn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-family: "DIN2014-demi", "Oswald", sans-serif;
  border-radius: 12px;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid transparent;
  outline: none;
  color: $color-white;

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-primary {
  background-color: $color-layer-1;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8), -4px -4px 7px rgba(255, 255, 255, 0.08);
  &:hover {
    background-color: $color-layer-2;
  }
  &:focus {
    border: 1px solid rgba(143, 146, 161, 0.4);
    background-color: $color-layer-1;
  }
  &:active {
    border: 1px solid transparent;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4), inset -3px -2px 10px rgba(255, 255, 255, 0.15),
      inset 5px 4px 10px rgba(0, 0, 0, 0.65);
  }
  &:disabled {
    color: rgba(143, 146, 161, 0.6);
    background-color: $color-layer-1;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8), -4px -4px 7px rgba(255, 255, 255, 0.08);
  }
  * {
    pointer-events: none;
  }
}
.btn-secondary {
  background-color: $color-layer-3;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &:focus {
    border: 1px solid $color-gray;
    background-color: $color-layer-3;
  }
  &:active {
    border: 1px solid $color-layer-2;
    background-color: $color-layer-2;
  }
  &:disabled {
    color: $color-gray;
    background-color: rgba(143, 146, 161, 0.2);
  }
  * {
    pointer-events: none;
  }
}
.btn-destructive {
  background-color: rgba(240, 20, 47, 0.4);
  &:hover {
    background-color: rgba(240, 20, 47, 0.6);
  }
  &:focus {
    border: 1px solid $color-gray;
    background-color: rgba(240, 20, 47, 0.4);
  }
  &:active {
    border: 1px solid transparent;
    background-color: rgba(240, 20, 47, 0.4);
  }
  * {
    pointer-events: none;
  }
}
.btn-outline {
  * {
    pointer-events: none;
  }
  height: 32px;
  outline: none;
  color: $color-gray;
  background: none;
  box-shadow: none;
  border: 2px solid rgba(143, 146, 161, 0.6);
  border-radius: 8px;
  padding: 5px 12px;
  &:hover {
    background: none;
    box-shadow: none;
    border: 2px solid $color-gray;
  }
  &:focus {
    background: none;
    box-shadow: none;
    border: 2px solid $color-gray;
  }
  &:active {
    box-shadow: none;
    color: $color-white;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(143, 146, 161, 0.6);

    svg {
      color: $color-white;
    }
  }
  &:disabled {
    background: none;
    box-shadow: none;
    color: rgba(143, 146, 161, 0.6);
    border: 2px solid rgba(143, 146, 161, 0.2);
  }
}

.btn-simple {
  * {
    pointer-events: none;
  }
  color: $color-white;
  background: transparent;
  // svg {
  //   filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.8)) drop-shadow(-4px -4px 7px rgba(255, 255, 255, 0.08));
  // }

  border-radius: 8px;
}

.btn-navigation {
  * {
    pointer-events: none;
  }
  background: $color-container;
  box-shadow: 0px 15px 36px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    border: 1px solid $color-gray;
    box-shadow: 0px 15px 36px rgba(0, 0, 0, 0.7);
  }
  &:active {
    border: none;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4), inset -3px -2px 10px rgba(255, 255, 255, 0.15),
      inset 5px 4px 10px rgba(0, 0, 0, 0.65);
  }
}

.btn-icon {
  background: transparent;
  &:hover {
    background-color: $color-layer-3;
  }
  &:focus {
    border: 1px solid $color-gray;
    background: transparent;
  }
  &:active {
    border: none;
    background-color: $color-layer-2;
  }
  * {
    pointer-events: none;
  }
}

.btn-clear {
  * {
    pointer-events: none;
  }

  border: none;

  background: $color-white;
  color: $color-black;

  &:hover {
    background: $color-off-white;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8), -4px -4px 7px rgba(255, 255, 255, 0.08);
  }
  &:focus {
    border: 2px solid $color-gray;
  }
  &:active {
    border: none;
    background: $color-off-white;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4), inset -3px -2px 10px rgba(255, 255, 255, 0.15),
      inset 5px 4px 10px rgba(0, 0, 0, 0.65);
  }
  &:disabled {
    color: $color-gray;
    background: $color-white;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8), -4px -4px 7px rgba(255, 255, 255, 0.08);
  }

  // &.btn-small {
  //   &:focus {
  //     padding: 2px 26px;
  //   }
  //   &:active {
  //     padding: 4px 28px;
  //   }
  // }

  // &.btn-medium {
  //   &:focus {
  //     padding: 6px 26px;
  //   }
  //   &:active {
  //     padding: 8px 28px;
  //   }
  // }

  // &.btn-large {
  //   &:focus {
  //     padding: 10px 26px;
  //   }
  //   &:active {
  //     padding: 12px 28px;
  //   }
  // }
}
.btn-xs {
  padding: 4px 10px;
  border-radius: 8px;
}
.btn-small {
  padding: 4px 28px;
  border-radius: 8px;
}
.btn-medium {
  padding: 8px 28px;
  border-radius: 8px;
}
.btn-large {
  padding: 12px 28px;
}
.btn-small.btn-with-icon {
  padding: 4px 16px;
}
.btn-medium.btn-with-icon {
  padding: 8px 16px;
}
.btn-large.btn-with-icon {
  padding: 12px 16px;
}
.left-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.right-icon {
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-left: 10px;
}

.btn-icon-only {
  &.btn-large {
    width: 48px;
    height: 48px;
    padding: 11px;
  }
  &.btn-medium {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
  &.btn-small {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
  &.btn-outline {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
}

.btn-navigation {
  &.btn-large,
  &.btn-medium,
  &.btn-small,
  &.btn-xs {
    border-radius: 15px;
  }
}

.center-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tooltip-container-button {
  margin-bottom: 4px;
}
