.session-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-inverse {
    flex-direction: column-reverse;
  }

  &.session-title-container-inverse.second-nav-container-session-title {
    .custom-input-wrapper {
      margin-bottom: 0;
      input.custom-input {
        padding-left: 8px;
        padding-top: 13px;
        margin-left: -9px;
        font-family: "DIN2014-bold";
      }
    }
  }

  .first-row {
    &:focus {
      outline: none;
    }
    align-items: center;
    display: flex;
    height: 40px;

    .icon-container {
      justify-content: center;
      align-items: center;
      margin-left: 24px;
    }

    &.with-hover {
      &:focus {
        outline: inherit;
      }

      &:hover {
        // border: 1px solid $color-gray;
        border: 1px solid rgba(143, 146, 161, 0.8);
        border-radius: 8px;
        padding: 0.25rem 0.5rem;
        min-width: 500px;
        position: relative;
        right: 9px;

        .icon-container {
          display: none;
        }
      }
    }
  }

  .custom-input-wrapper {
    margin-bottom: 0;
  }

  .machine-name {
    margin-bottom: 4px;
    color: $color-off-white;
  }
}
