.folders {
  background: $color-container-secondary;
  padding: 2rem 0;
  min-height: calc(100vh - 313px);

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1.75rem 1rem 1.75rem;

    &-title {
      text-transform: uppercase;
    }
  }

  &-list {
    list-style: none;
    padding-inline-start: 0;

    &-item {
      align-items: center;
      .typography {
        color: $color-gray;
      }
      cursor: pointer;
      display: flex;
      font-size: 0.875rem;
      height: 2.75rem;
      padding: 0.6rem 1.75rem;

      &-active {
        .typography {
          color: $color-white;
        }
      }

      &-active,
      &:hover {
        background: rgba(143, 146, 161, 0.2);
      }
    }
  }
}
