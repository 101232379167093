.card-machine-status-outer-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.card-machine-status-thumbnail-container {
  display: flex;
  margin-right: 0.8125rem;
}

.card-machine-status-info-container {
  display: flex;
  width: 200px;
  flex-direction: column;
}

.card-machine-status-live-label {
  color: $color-orange;
  margin-right: 10px;
}

.card-machine-status-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-machine-status-name-container {
  margin-bottom: 10px;
}

.card-machine-status-controls-container {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: flex-start;
}

.card-machine-status-footer {
  display: flex;
  justify-content: flex-start;

  padding-bottom: 1rem;
  border-bottom: 1px solid $color-divider;

  &-icon {
    margin-left: 0.25rem;
    margin-right: 1.1rem;
  }
}

.card-machine-status-header {
  border-bottom: 1px solid $color-divider;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  &.replay {
    border-bottom: none;
  }
}

.card-machine-status-footer-info .card-machine-status-footer-subtitle {
  color: $color-gray;
  font-size: 0.75rem;
}

.card-machine-status-footer-info .card-machine-status-footer-title {
  font-size: 0.85rem;
}

.card-machine-status-jobs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &-row {
    margin-top: 1rem;
    display: inline-flex;
  }
}
