.session-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 35px;

  .session-header-with-border {
    min-width: 827px;
    flex: 1;
    padding-right: 61px;
    border-bottom: 1px solid $color-divider;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
  }

  .session-row-value-column {
    flex-basis: 149px;
    min-width: 149px;
    flex: 1;
  }

  .session-row-value-column-small {
    flex-basis: 170px;
    min-width: 170px;
  }

  .session-row-thumbnail-container {
    flex-basis: 50px;
    min-width: 50px;
  }

  .session-row-container-thumbnail-runname {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}
