main {
  .react-datepicker.custom-date-picker {
    font-family: "DIN2014";
    font-size: 16px;
    color: $color-white;
    border: none;
    width: 100% !important;

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: transparent;
      outline: none;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      width: 100%;
      margin: 10px 0;
      justify-content: space-around;
    }

    .react-datepicker__day-names {
      padding: 0 65px;

      .react-datepicker__day-name {
        &:first-child {
          position: relative;
          left: 5px;
        }

        &:last-child {
          position: relative;
          right: 5px;
        }
      }
    }

    .custom-header {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .react-datepicker__month {
      margin: 0;
      width: 389px;
      padding: 0px 28px 22px 28px;
    }

    .react-datepicker__header {
      padding: 22px 28px 0px 28px;
      border: none;
      width: 100%;
      background: $color-layer-light;

      .react-datepicker__day-name {
        margin: 0;
        padding: 0px 6px;
        color: $color-gray;
        width: 2.8em;
        margin: 0.12em;
      }
    }

    .react-datepicker__day {
      color: $color-white;
      width: 32px;
      height: 32px;
      line-height: 32px;

      &--disabled,
      &--excluded {
        color: rgba(143, 146, 161, 0.6);
      }

      &--selected {
        border-radius: 4px;
        background-color: $color-orange;
      }

      &:hover {
        border-radius: 4px;
        background-color: $color-white;
        color: $color-dark-gray;
      }

      &:active {
        border: none;
        outline: none;
      }

      &.react-datepicker__day--outside-month {
        color: rgba(143, 146, 161, 0.6);
      }
    }

    .react-datepicker__month-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      border: none;
      background: $color-layer-2;
      box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
      background-color: $color-layer-light;
      border-radius: 4px;
    }

    .calendar-button {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.2);
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
