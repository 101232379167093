.project-settings {
  &-tabs {
    display: flex;
    margin-bottom: 40px;
    &-tab {
      .btn {
        color: $color-gray;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
      }
      margin-right: 32px;
      border-bottom: 4px solid transparent;
      &.selected {
        .btn {
          color: $color-white;
        }
        border-bottom: 4px solid $color-orange;
      }
    }
    &-content {
      display: flex;
      justify-content: flex-start;
    }
  }

  &-button-tab {
    justify-content: flex-start;
    text-align: left;
    width: 217px;
    border-radius: 4px;
    padding: 9px 16px;

    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .modal-new-project-section {
    background-color: $color-container-secondary;
  }

  .card-project-overview {
    width: 670px;

    &-subtitle {
      margin-bottom: 24px;
    }

    &-top-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      &-button {
        margin-top: 22px;
        margin-left: 32px;
      }
    }

    &-parts-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-eyebrow {
      margin-bottom: 24px;
    }

    .custom-input.custom-input-number-with-buttons {
      -moz-appearance: textfield;
      padding: 14px 6.5rem 14px 4rem !important;
      text-align: center;
    }

    &-input {
      &-plural,
      &-singular {
        padding-left: 32px;
        .custom-input-wrapper {
          text-align: left;
          position: relative;

          &:after {
            color: $color-white;
            position: absolute;
            top: 13px;
            right: 60px;
          }
        }
      }

      &-plural .custom-input-wrapper::after {
        content: " Sessions";
      }

      &-singular .custom-input-wrapper::after {
        content: " Sessions";
      }
    }
  }
  padding: 40px 60px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-buttons {
      display: flex;
      .btn:first-child {
        margin-right: 24px;
      }
    }
  }

  &-parts-tab {
    display: flex;

    &-left {
      width: 217px;
      margin-right: 16px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &-features-tab {
    width: 217px;
    margin-right: 16px;
  }
}

.part-modal,
.feature-modal {
  display: flex;
  padding: 0 16px;
  background: $color-container-secondary;
}

.part-modal {
  &-save-button {
    margin: 0 60px 24px auto;
  }
}

.feature-modal {
  &-save-button {
    margin: 0 60px 24px auto;
  }
}
