.custom-input-wrapper {
  display: flex;

  .selection-input {
    @include input-common-styles("325px", "48px");

    padding-left: 48px;
    overflow: hidden;
    //border: 1px solid $color-gray;
    border-radius: 8px;
    cursor: pointer;

    &.selection-input-checked {
      border: 1px solid transparent;
      background: $color-layer-3;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6), -4px -4px 6px rgba(255, 255, 255, 0.04);
      border-radius: 8px;
    }
  }

  .checkbox-container {
    margin: 0;
    width: 0;
    height: 0;
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 14px;
    left: 16px;
  }
}
