.program-details-card {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: $color-container-secondary;

  &-header,
  &-footer {
    width: 100%;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-footer {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    .custom-input {
      display: flex;
      align-items: center;
      padding: 0px 16px;
      width: 277px;
      height: 48px;
      border: 1px solid $color-gray;
      border-radius: 12px;
    }

    &-title {
      display: inline-block;
      margin-left: 5px;
    }
  }
}
