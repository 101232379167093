.sidebar-menu {
  box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.4);
  background: $color-layer-2;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  transition: 0.5s ease-in-out;
  transform: translateX(-300px);
  width: 300px;
  z-index: 2;

  + .App {
    transition: 0.5s ease-in-out;
  }

  &-open {
    transform: translateX(0);

    + .App {
      transform: translateX(300px);
      transition: 0.5s ease-in-out;
    }
  }

  &-section-title {
    color: #e5e5e5;
    font-size: 0.75rem;
    font-family: "DIN2014-bold";
    margin-left: 34px;
    margin-bottom: 1.15rem;
  }

  &-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0;

    .vertical-flex {
      cursor: pointer;
    }

    &-icon {
      color: $color-white;

      &:first-child {
        margin-right: 0.25rem;
      }
    }

    &-close-button {
      //padding: 14px 2px;
      margin-top: 15px;
      margin-left: 8px;
    }

    &-logo-container {
      padding-top: 15px;
      position: relative;
      right: 0.35rem;
      top: 0.55rem;
    }
  }

  &-list {
    margin-bottom: 40px;
    &-submenu {
      list-style: none;
      padding-inline-start: 0;

      &-list {
        margin-bottom: 10px;
        align-items: center;
        border-radius: 0.5rem;
        color: $color-white;
        cursor: pointer;
        display: flex;
        padding: 10px 34px;

        img {
          width: 24px;
          margin-right: 15px;
        }

        &:hover {
          background: rgba(143, 146, 161, 0.2);
        }

        .icon-container {
          margin-right: 1rem;
        }
      }
    }
  }

  &-favorites {
    &-submenu {
      list-style: none;
      padding-inline-start: 0;

      &-content {
        border-radius: 8px;
        padding: 10px;
        padding-left: 5px;
        margin-bottom: 10px;

        &-inner {
          align-items: center;
          display: flex;
        }

        &:hover {
          background: rgba(143, 146, 161, 0.2);
        }

        &:last-child {
          margin-bottom: 0;
        }

        .icon-container {
          margin-right: 1rem;
        }
      }
    }
  }

  &-account {
    margin-top: auto;
    margin-bottom: 24px;

    .card {
      background: $color-layer-2;
      box-shadow: -3px -5px 4px rgba(140, 140, 140, 0.0500164);
      border-radius: 1.875rem;
      border: 2px solid #1d1d1d;
      padding: 1.3125rem;

      .title {
        color: $color-gray;
      }
    }

    &-btn {
      cursor: pointer;
      padding: 5px 12px;
    }

    &-bars {
      margin-top: 1.25rem;

      &-labels {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.5px;

        &-used {
          padding-right: 4px;
        }
      }
    }
  }
}

.App-shadow {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
