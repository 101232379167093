.modal-add-program {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3.25rem;

  &-title {
    margin-bottom: 0.75rem;
  }

  &-buttons {
    align-self: flex-end;
    display: flex;
    margin-top: 1.5rem;

    button:first-child {
      margin-right: 1rem;
    }
  }
}
