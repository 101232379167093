.machine-session-goals {
  padding: 32px 64px;

  .machine-session-goals-body {
    margin-top: 10px;
  }

  .machine-session-goals-input {
    margin: 24px 0px;
  }

  .machine-session-goals-divider {
    width: 520px;
    height: 0px;
    margin-top: 40px;
    border-bottom: 1px solid $color-divider;
  }

  .machine-session-goals-notes {
    margin-top: 32px;
  }
}
