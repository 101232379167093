.project-card-outer-container {
  background: $color-container-secondary;

  box-shadow: -4px -4px 12px rgba(255, 255, 255, 0.08), 6px 6px 10px rgba(0, 0, 0, 0.8);
  border: 1px solid $color-layer-3;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;

  .dropdown-actions-list {
    position: absolute;
  }

  &:hover {
    background-color: lighten($color-container-secondary, 3%);
    transition: background-color 0.1s ease-in-out;

    .project-card-footer {
      background-color: lighten($color-layer-1, 3%);
      transition: background-color 0.1s ease-in-out;
    }
  }

  .project-card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 1.5rem;
    height: 270px;

    .project-card-content-top {
      .project-card-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        .project-card-icons {
          display: flex;
        }
      }

      .project-card-title {
        word-wrap: break-word;
      }

      .project-card-subtitle {
        margin-top: 0.625rem;
        //@include overflow();
      }
    }

    .project-card-content-bottom {
      display: flex;
      flex-direction: column;

      &-info {
        margin-bottom: 6px;
      }
    }
  }

  .project-card-footer {
    align-items: center;
    background: $color-layer-1;
    display: flex;
    justify-content: flex-start;
    padding: 15px 18px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

    .project-card-status-text {
      font-size: 1rem;
    }
  }
}
